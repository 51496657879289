import React from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { useAuth } from "../auth/useAuth";

import firebase from "../../services/firebase";
import "firebase/firestore";

import PhoneInput from "react-phone-input-2";
import "../../assets/inputs/phone.css";
import { ClickableContact } from "../helpers/Helpers";

export default function InformationView({ value }: any) {
  const { user } = useAuth();

  const userRef = firebase.firestore().collection("users").doc(user.uid);

  const formValues = {
    fName: value?.first_name || "",
    lName: value?.last_name || "",
    phone: value?.phone || "",
  };
  const toast = useToast();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      onSubmit={async (values: any, actions: any) => {
        if (JSON.stringify(values) === JSON.stringify(formValues)) {
          toast({
            title: "No Changes 🤔",
            status: "info",
            duration: 9000,
            isClosable: true,
          });
        } else {
          await userRef
            .set(
              {
                first_name: values.fName,
                last_name: values.lName,
                phone: values.phone,
              },
              { merge: true }
            )
            .then(() => {
              toast({
                title: "Profile Updated 👍",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            })
            .catch(() => {
              toast({
                title: (
                  <Text textAlign={"center"}>
                    Error occurred, please try again later. If the issue
                    persists please <ClickableContact />
                  </Text>
                ),
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        }
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Stack spacing={4}>
            <Stack w={"full"} direction={{ base: "column", md: "row" }}>
              <Field name="fName">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="fName">First Name</FormLabel>
                    <Input
                      {...field}
                      id="fName"
                      placeholder="Jane"
                      autoComplete="given-name"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="lName">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="lName">Last Name</FormLabel>
                    <Input
                      {...field}
                      id="lName"
                      placeholder="Doe"
                      autoComplete="family-name"
                    />
                  </FormControl>
                )}
              </Field>
            </Stack>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl isDisabled>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input {...field} value={user.email} />
                </FormControl>
              )}
            </Field>
            <Field name="phone">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.phone && form.touched.phone}
                >
                  <FormLabel htmlFor="phone">Phone Number</FormLabel>

                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      id: "phone",
                    }}
                    {...field}
                    as={Input}
                    id="phone"
                    country={"us"}
                    countryCodeEditable={false}
                    onChange={(e) => {
                      setFieldValue("phone", e);
                    }}
                    dropdownStyle={{
                      bottom: "100%",
                    }}
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Stack spacing={10}>
              <Button
                mt={4}
                variant="outline"
                isLoading={isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
