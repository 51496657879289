import React from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { PageHeader } from "../components/helpers/Helpers";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useHistory } from "react-router-dom";

export default function Support(props: any) {
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();
  function validateMessage(value: any) {
    let error;
    if (!value) {
      error = "Message is required";
    } else if (value.length <= 20) {
      error = "Message needs to be at least 20 characters long";
    }
    return error;
  }
  function validateReason(value: any) {
    let error;
    if (!value || value === "0") {
      error = "Reason is required";
    }
    return error;
  }

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Support"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
          fontFamily={"Gagalin"}
        >
          <Text textAlign={"center"}>
            Please describe the issue you are facing in details and we will get
            back to you as soon as possible
          </Text>
          <Formik
            initialValues={{ message: "", reason: "0", auth: "" }}
            onSubmit={async (values, actions) => {
              try {
                await firebase
                  .firestore()
                  .collection("support")
                  .add({
                    message: values.message,
                    reason: values.reason,
                    uid: user.uid,
                  })
                  .then(() => {
                    toast({
                      title: "Report Sent 👍",
                      description:
                        "We have received your report and will get back to your shortly",
                      status: "success",
                      duration: 6000,
                      isClosable: true,
                    });
                    actions.setSubmitting(false);
                    history.push("/dashboard");
                  });
              } catch (error) {
                actions.setErrors({
                  auth: "Error occurred please try again later",
                });
                actions.setSubmitting(false);
              }
            }}
          >
            {({ errors, isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  {errors.auth && (
                    <Text align="center" fontSize="sm" mt="2" color={"red.500"}>
                      {errors.auth}
                    </Text>
                  )}
                  <Field name="reason" validate={validateReason}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.reason && form.touched.reason}
                      >
                        <FormLabel htmlFor="reason">Reason</FormLabel>
                        <Select {...field} id="reason">
                          <option value="0" disabled>
                            Select Reason
                          </option>
                          <option value="subscription">
                            Subscription Issue
                          </option>
                          <option value="payment">Payment Issue</option>
                          <option value="telegram">Telegram</option>
                          <option value="discord">Discord</option>
                          <option value="other">Other</option>
                        </Select>
                        <FormErrorMessage>
                          {form.errors.reason}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="message" validate={validateMessage}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.message && form.touched.message}
                      >
                        <FormLabel htmlFor="message">Message</FormLabel>
                        <Textarea
                          {...field}
                          id="message"
                          placeholder="Describe your issue in detail"
                          type="text"
                        />
                        <FormErrorMessage>
                          {form.errors.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Button
                      mt={4}
                      isLoading={isSubmitting}
                      type="submit"
                      variant={"outline"}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Flex>
  );
}
