import { extendTheme } from "@chakra-ui/react";

// MAIN ORANGE 900
// SECONDARY CARDS gray.800

export default extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Gagalin",
  },
  colors: {
    orange: {
      900: "#f5b002",
    },
    blue: {
      900: "#0D0C1D",
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "orange.900",
        fontFamily: "Gagalin",
        _focus: {
          boxShadow: "orange.900",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          marginX: 4,
        },
      },
    },
    CloseButton: {
      baseStyle: (props) => ({
        bg: props.colorMode === "dark" ? "transparent" : "orange.900",
        color: "white",
        border: "1px solid",
        borderColor: "orange.900",
        _hover: {
          bg: props.colorMode === "dark" ? "orange.900" : "transparent",
          color: props.colorMode === "dark" ? "white" : "orange.900",
        },
        _focus: {
          boxShadow: "orange.900",
        },
      }),
    },
    Spinner: {
      sizes: {
        xl: {
          w: "8rem",
          h: "8rem",
        },
        lg: {
          w: "4rem",
          h: "4rem",
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          fontWeight: "600",
          _focus: {
            boxShadow: "orange.900",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        letterSpacing: "1px",
      },
    },
    List: {
      baseStyle: {
        item: {
          fontFamily: "Gagalin",
          letterSpacing: "1.5px",
          fontSize: "18px",
        },
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "orange.900",
        },
      },
      variants: {
        outline: (props) => ({
          bg: props.colorMode === "dark" ? "transparent" : "orange.900",
          letterSpacing: "1.5px",
          borderColor: "orange.900",
          fontFamily: "Gagalin",
          color: "white",
          _hover: {
            bg: {
              base: "transparent",
              md: props.colorMode === "dark" ? "orange.900" : "transparent",
            },
            color: props.colorMode === "dark" ? "white" : "orange.900",
          },
          _focus: {
            boxShadow: "orange.900",
          },
        }),
        delete: (props) => ({
          bg: props.colorMode === "dark" ? "red.500" : "red.500",
          letterSpacing: "1.5px",
          borderColor: "red.500",
          fontFamily: "Gagalin",
          color: "white",
          _hover: {
            bg: props.colorMode === "dark" ? "red.400" : "red.400",
            color: props.colorMode === "dark" ? "white" : "white",
          },
          _focus: {
            boxShadow: "red.500",
          },
        }),
      },
    },
    Text: {
      variants: {
        Gagalin: {
          fontFamily: "Gagalin",
          letterSpacing: "1px",
        },
      },
    },
    Box: {
      baseStyle: (props) => ({
        bg: props.colorMode === "dark" ? "gray.800" : "white",
      }),
      variants: {
        main: {
          bg: "white",
        },
        slidersBTN: {
          borderColor: "orange.900",
          _hover: {
            bg: "orange.900",
            color: "white",
          },
        },
      },
    },
    Table: {
      variants: {
        simple: {
          th: {
            color: "orange.900",
            fontFamily: "Gagalin",
          },
          td: {
            fontFamily: "Gagalin",
            fontSize: "20px",
          },
        },
      },
    },
  },
});
