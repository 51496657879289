import React from "react";
import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
  Heading,
  Text,
} from "@chakra-ui/react";

import {
  ErrorCard,
  PageHeader,
  LoadingCard,
  ClickableContact,
} from "../components/helpers/Helpers";

import firebase from "../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import parse from "html-react-parser";

export default function Resources(props: any) {
  const userRef = firebase.firestore().collection("content").doc("Resources");

  const [value, loading, error] = useDocumentData(userRef);

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Resources"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {value && (
            <Accordion defaultIndex={[0]}>
              {value.items.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionButton _focus={{ boxShadow: "orange.900" }}>
                    <Box flex="1" textAlign="left">
                      <Heading
                        color={"orange.900"}
                        fontSize={{ base: "2xl", md: "3xl" }}
                      >
                        {item.topic}
                      </Heading>
                    </Box>
                    <AccordionIcon color={"orange.900"} />
                  </AccordionButton>
                  <AccordionPanel
                    px={{ base: 5, md: 10 }}
                    py={4}
                    style={{ whiteSpace: "pre-line" }}
                    fontFamily={"Gagalin"}
                  >
                    {parse(item.content.replace(/\\n/g, "\n"))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}

          {error && <ErrorCard />}
          {loading && <LoadingCard />}

          <Text
            textAlign={"center"}
            color={"orange.900"}
            variant={"Gagalin"}
            mt={6}
          >
            If you have any more questions please <ClickableContact />
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
