import React, { useState } from "react";
import { Stack, useColorModeValue } from "@chakra-ui/react";

import { motion } from "framer-motion";

import Welcome from "../components/frontpage/Welcome";
import Details from "../components/frontpage/Details";

import ScrollToTopOnMount from "../services/scrollToTop";
import { useLastLocation } from "react-router-last-location";
import { scroller } from "react-scroll";

import { useAuth } from "../components/auth/useAuth";
import { Redirect } from "react-router-dom";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
  exit: {
    opacity: 0,
    transition: { ease: "easeInOut", duration: 0.2 },
  },
};
export default function HomePage() {
  const [position, setPosition] = useState(1);

  const lastLocation = useLastLocation();

  if (
    (lastLocation?.pathname === "/statistics" ||
      lastLocation?.pathname === "/signup") &&
    position === 1
  ) {
    setPosition(0);
    setTimeout(() => scroller.scrollTo("service", true), 100);
  }

  const { user } = useAuth();
  const colors = [useColorModeValue("gray.50", "blue.900")];

  return (
    <>
      {!user ? (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          exit="exit"
        >
          {position ? <ScrollToTopOnMount /> : ""}
          <Stack bg={colors[0]}>
            <Welcome />
            <Details />
          </Stack>
        </motion.div>
      ) : (
        <Redirect to="/dashboard" />
      )}
    </>
  );
}
