import React from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ListItem,
  UnorderedList,
  Heading,
} from "@chakra-ui/react";

import { PageHeader } from "../components/helpers/Helpers";

export default function Terms(props: any) {
  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
          fontFamily={"Gagalin"}
        >
          <Tabs isFitted mt={4} textAlign={"left"}>
            <TabList mb="1em">
              <Tab
                _selected={{ color: "orange.900", borderColor: "orange.900" }}
                _focus={{ borderColor: "orange.900" }}
              >
                Terms &amp; Conditions
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Heading fontSize={"xl"} color={"orange.900"}>
                  DISCLAIMER
                </Heading>
                <Flex p={4} flexDir={"column"}>
                  <Text>
                    CryptoKnights is an educational service. As such, opinions
                    and trade recommendations in both our Discord server and
                    other platforms are solely our own. CryptoKnights does not
                    indicate that any subscriber will achieve profits or losses
                    similar to those discussed on this website. The past
                    performance of any trading system or methodology is not
                    indicative of future results. CryptoKnights does not
                    guarantee any claims or recommendations and is not
                    responsible for any losses incurred. Securities and options
                    trading are highly speculative investments and involve
                    substantial risk. If you do not fully understand these
                    risks, seek independent advice from a licensed financial
                    advisor.
                  </Text>
                  <Text>
                    By joining the CryptoKnights Discord or Telegram server, you
                    are agreeing to the following conditions. Any violation of
                    these will result in immediate cancellation of your
                    membership and ban from our all media outlets. We are
                    dedicated to maintaining the integrity and quality of the
                    service and will enforce the following:
                  </Text>
                  <UnorderedList pl={4}>
                    <ListItem>
                      Be respectful and mindful of other members. If you are
                      consistently offensive, you will receive a warning. If it
                      persists your membership will be terminated.
                    </ListItem>
                    <ListItem>Do not spam or troll.</ListItem>
                    <ListItem>
                      No self-promoting or advertising of products/services of
                      any kind without prior administrative permission.
                    </ListItem>
                    <ListItem>
                      NSFW comments and images are strictly prohibited.
                    </ListItem>
                    <ListItem>
                      No solicitations for funding or account management.
                    </ListItem>
                    <ListItem>
                      Any redistribution of our content is strictly prohibited
                      and is considered a violation of our intellectual property
                      and will result in copyright infringement charges and an
                      instantaneous ban from our services.
                    </ListItem>
                    <ListItem>
                      Members are fully responsible for their own trading
                      activity.
                    </ListItem>
                    <ListItem>
                      By subscribing to our service, you accept that you have
                      read and understand this Disclaimer.
                    </ListItem>
                  </UnorderedList>
                </Flex>
                <Text>
                  <Heading fontSize={"xl"} color={"orange.900"}>
                    REFUND POLICY
                  </Heading>
                </Text>
                <Flex p={4} flexDir={"column"}>
                  <Text>
                    Subscriptions are non-refundable due to the nature of the
                    services provided.
                  </Text>
                </Flex>
                <Text>
                  <Heading fontSize={"xl"} color={"orange.900"}>
                    LIMITATION OF LIABILITY
                  </Heading>
                </Text>
                <Flex p={4} flexDir={"column"}>
                  <Text>
                    IN NO EVENT WILL THE CONTENT CREATOR, ITS AFFILIATES,
                    PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES
                    OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                    PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST
                    DATA ARISING FROM YOUR USE OF THE SERVER, OR ANY OF THE
                    CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR
                    DOWNLOADED FROM THE SERVER, EVEN IF THE CONTENT CREATOR OR
                    ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS,
                    OFFICERS, EMPLOYEES OR AGENTS IS AWARE OR HAS BEEN ADVISED
                    OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING
                    TO THE CONTRARY CONTAINED HEREIN, THE LIABILITY OF THE
                    CONTENT CREATOR AND ITS AFFILIATES, PARTNERS, MEMBERS,
                    MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS TO YOU
                    FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
                    ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF
                    ANY, BY YOU TO THE CONTENT CREATOR FOR THE SERVER IN THE
                    IMMEDIATELY PRECEDING TWELVE MONTHS, BUT IN NO CASE WILL
                    SUCH LIABILITY TO YOU EXCEED $1000. YOU ACKNOWLEDGE THAT IF
                    NO FEES ARE PAID TO THE CONTENT CREATOR FOR THE SERVER, YOU
                    SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE
                    PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF
                    ANY KIND FROM THE CONTENT CREATOR, REGARDLESS OF THE CAUSE
                    OF ACTION. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
                    IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                    ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY
                    TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  </Text>
                </Flex>
                <Text>
                  <Heading fontSize={"xl"} color={"orange.900"}>
                    Get in touch
                  </Heading>
                </Text>
                <Flex p={4} flexDir={"column"}>
                  <Text>
                    If you have any questions or suggestions about our Terms
                    &amp; Conditions, do not hesitate to contact us at
                    info@thecryptoknights.io
                  </Text>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Flex>
  );
}
