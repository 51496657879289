import React from "react";
import { Route } from "react-router-dom";

import { Helmet } from "react-helmet";

export default function OpenRoute({ component: Component, ...rest }: any) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{rest.title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      ></Route>
    </>
  );
}
