import React from "react";
import {
  Box,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  Heading,
  chakra,
  Stack,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";

import { Link } from "react-router-dom";
import { TestimonialsSlider } from "./slider";

import firebase from "../../services/firebase";
import "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { MdShowChart } from "react-icons/md";

export default function Details() {
  const userRef = firebase
    .firestore()
    .collection("testimonials")
    .where("approved", "==", true);

  const [values] = useCollectionData(userRef);

  return (
    <Flex
      p={5}
      minH="96vh"
      justify={"center"}
      align={"center"}
      grow={1}
      direction={"column"}
      id="service"
    >
      <Flex
        w={"full"}
        flexDir={"column"}
        alignItems={"center"}
        mb={"auto"}
        mt={20}
      >
        <Heading mb={20} size={"3xl"}>
          Who <chakra.span color={"orange.900"}>We</chakra.span> Are
        </Heading>
        <Stack spacing={8} px={"15%"} direction={{ base: "column", md: "row" }}>
          <DetailsBox
            title="Trading Signals"
            description="Our trade signals are mainly curated for some of the leading exchanges out there, Binance and Kucoin. These calls are made when there’s a clear view of the direction we are heading in. We offer both leverage and spot trades with low risk profiles. That is what makes us safer than other groups!"
          />
          <DetailsBox
            title="Education"
            description="Learn about the technical analysis of the crypto market as well as it’s fundamentals. These two go both hand in hand as news on coins does affect the price immensely. We are more than just your average signals channel!"
          />
        </Stack>
        <Stack
          spacing={8}
          px={"15%"}
          direction={{ base: "column", md: "row" }}
          mt={10}
        >
          <DetailsBox
            title="Community"
            description="Join a community of mixed levels of trading experiences and enjoy the learning curve. Our group consists of friendly and respectful members who have been through it all and others that are new to the space!"
          />
          <DetailsBox
            title="Tools"
            description="We have news bots getting you the latest crypto update the second it is posted. A portfolio tracker allowing you to have all your wallet information in one neat interface!"
          />
        </Stack>
        <Stack
          spacing={{ base: 10, md: 20 }}
          mt={{ base: 10, md: 20 }}
          direction={{ base: "column", md: "row" }}
        >
          <ButtonsBox
            title={"Statistics"}
            link={"statistics"}
            text={"Join the CryptoKnigts"}
            translate={"-30px"}
            icon={MdShowChart}
          />

          <ButtonsBox
            title={"Sign Up"}
            link={"signup"}
            text={"View our past month's earnings"}
            translate={"30px"}
            icon={FaUserPlus}
          />
        </Stack>
      </Flex>
      <Flex
        mt={20}
        w={"full"}
        h={"350px"}
        alignItems={"center"}
        flexDir={"column"}
      >
        <Heading color={"orange.900"} size={"3xl"} mb={{ base: 3, md: 5 }}>
          Testimonials
        </Heading>
        {values && <TestimonialsSlider values={values} />}
      </Flex>
    </Flex>
  );
}

function DetailsBox(props: any) {
  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      boxShadow="xl"
      p={5}
      flex="1"
      borderRadius="md"
      justify={"center"}
      align={"center"}
    >
      <Heading fontSize="xl" color={"orange.900"}>
        {props.title}
      </Heading>
      <Text mt={4}>{props.description}</Text>
    </Box>
  );
}

function ButtonsBox(props: any) {
  return (
    <Box
      as={Link}
      to={`/${props.link}`}
      borderColor={"orange.900"}
      bg={useColorModeValue("orange.900", "transparent")}
      color={"white"}
      _hover={{
        bg: "orange.900",
        color: "white !important",
        cursor: "pointer",
        transform: `translateX(${props.translate})`,
        msTransform: `translateX(${props.translate})`,
      }}
      transition={"transform 1.5s"}
      px={5}
      py={2}
      borderWidth="1px"
      flex="1"
      borderRadius="md"
    >
      <Heading fontSize="xl">
        <Icon as={props.icon} mr={3} />
        {props.title}
      </Heading>
      <Text mt={4} textAlign={"center"}>
        {props.text}
      </Text>
    </Box>
  );
}
