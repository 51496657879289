import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Stack,
  Button,
  Icon,
  SimpleGrid,
  useToast,
  Link as LinkUI,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { FaTelegram, FaDiscord, FaCheckCircle } from "react-icons/fa";

import { useAuth } from "../auth/useAuth";
import firebase from "../../services/firebase";
import "firebase/firestore";
import TelegramLoginButton from "react-telegram-login";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

import "./accounts.css";

export function AccountView({ value }: any) {
  const { user } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const [btn2Loading, setBtn2Loading] = useState(false);

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (query.get("code") && !value?.accounts?.discord) {
      handleDiscordResponse(query.get("code"));
    }
  }, []);

  const toast = useToast();
  const handleTelegramResponse = async (response) => {
    if (response.id) {
      setBtnLoading(true);
      await firebase
        .firestore()
        .collection("telegram_verification")
        .doc(response.id + "")
        .set(
          {
            uid: user.uid,
            username: response.username,
          },
          { merge: true }
        )
        .then((ok) =>
          setTimeout(function () {
            setBtnLoading(false);
            toast({
              title: "Telegram Verified",
              description:
                "You will receive a message from our Bot detailing how you can join our groups",
              duration: 9000,
              position: "bottom",
              status: "success",
            });
          }, 6000)
        )
        .catch((e) => {
          setBtnLoading(false);
          toast({
            title: "Verification Failed",
            description: "Please contact us if the issue persists.",
            duration: 9000,
            position: "bottom",
            status: "error",
          });
        });
    }
  };

  const handleDiscordResponse = async (response) => {
    if (response) {
      setBtn2Loading(true);
      await firebase
        .firestore()
        .collection("discord_verification")
        .doc(user.uid)
        .set(
          {
            code: response,
          },
          { merge: true }
        )
        .then((ok) =>
          setTimeout(function () {
            setBtn2Loading(false);
            toast({
              title: "Discord Verified",
              description: "You have been successfully verified.",
              duration: 5000,
              position: "bottom",
              status: "success",
            });
          }, 6000)
        )
        .catch((e) => {
          setBtn2Loading(false);
          toast({
            title: "Verification Failed",
            description: "Please contact us if the issue persists.",
            duration: 9000,
            position: "bottom",
            status: "error",
          });
        });
    }
  };

  return (
    <Flex flexDir={"column"}>
      {value.subscription !== "active" ? (
        <Flex
          mb={6}
          justifyContent={"center"}
          flexDir={"column"}
          align={"center"}
        >
          <Text textAlign={"center"} maxW={"50%"}>
            You currently don't have an active subscription. To be able to join
            our Discord and Telegram groups please purchase a subscription
          </Text>
          <Button
            variant={"outline"}
            mt={8}
            w={"50%"}
            as={Link}
            to={"subscriptions"}
          >
            Subscribe
          </Button>
        </Flex>
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          verticalAlign={"center"}
        >
          <Stack align={"center"} p={"5"}>
            <SocialIcon icon={FaTelegram} text="Telegram" color={"#0088CC"} />
            {value?.accounts?.telegram ? (
              <SocialUsername text={value?.accounts?.telegram?.username} />
            ) : !btnLoading ? (
              <TelegramLoginButton
                dataOnauth={handleTelegramResponse}
                botName="CryptoKnights_Bot"
              />
            ) : (
              <Flex align={"center"}>
                <Spinner size={"sm"} mr={2} />
                <Text>Please wait</Text>
              </Flex>
            )}
          </Stack>
          <Stack align={"center"} p={"5"}>
            <SocialIcon icon={FaDiscord} text="Discord" color={"#7289DA"} />
            {value?.accounts?.discord ? (
              <SocialUsername text={value?.accounts?.discord?.username} />
            ) : !btn2Loading ? (
              <Button
                w={{ base: "75%", md: "50%" }}
                as={LinkUI}
                className={"discordButton"}
                href={
                  "https://discord.com/api/oauth2/authorize?client_id=815130620564078592&redirect_uri=https%3A%2F%2Fthecryptoknights.io%2Fprofile&response_type=code&scope=identify%20guilds.join"
                }
              >
                <Flex fontFamily={"arial"} align={"center"} justify={"center"}>
                  <Icon as={FaDiscord} mr={2} w={"24px"} h={"24px"} />
                  <Text>Verify Discord</Text>
                </Flex>
              </Button>
            ) : (
              <Flex align={"center"}>
                <Spinner size={"sm"} mr={2} />
                <Text>Please wait</Text>
              </Flex>
            )}
          </Stack>
        </SimpleGrid>
      )}
    </Flex>
  );
}

export function SocialIcon({ icon, text, color }: any) {
  return (
    <>
      <Flex
        w={20}
        h={20}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={6}
      >
        <Icon as={icon} w={32} h={32} color={color} />
      </Flex>
      <Text mb={6}>{text}</Text>
    </>
  );
}

export function SocialUsername({ text }: any) {
  return (
    <>
      <Heading color={"orange.900"} fontSize={"3xl"}>
        {text}
      </Heading>
      <Text color={"green.400"}>
        <Icon as={FaCheckCircle} mr={1} />
        Verified
      </Text>
    </>
  );
}
