import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Link as LinkUI,
  Button,
  Heading,
  Text,
  useColorModeValue,
  HStack,
  Img,
  Alert,
  AlertIcon,
  AlertDescription,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useAuth } from "../components/auth/useAuth";
import { Formik, Form, Field } from "formik";
import { Redirect } from "react-router-dom";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

export default function SignIn(props: any) {
  const { user, TwitterAuth, FacebookAuth, GoogleAuth, ErrorHandlingMsg } =
    useAuth();
  const [authError, setAuthError] = useState(null);
  const colors = [
    useColorModeValue("gray.50", "blue.900"),
    useColorModeValue("white", "gray.800"),
  ];
  if (user) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    <motion.div initial="hidden" animate="visible" variants={containerVariants}>
      <Flex minH="96vh" align={"center"} justify={"center"} bg={colors[0]}>
        <Flex flexDir={"column"} mx={"auto"} maxW={"md"} py={12} px={6}>
          <Stack align={"center"}>
            <Img src={"./logo512.png"} w={"10rem"} />
            <Heading fontSize={"4xl"} textAlign="center">
              Sign <chakra.span color={"orange.900"}>In</chakra.span>
            </Heading>
          </Stack>
          {authError && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{authError}</AlertDescription>
            </Alert>
          )}
          <Box
            rounded={"lg"}
            bg={colors[1]}
            boxShadow={"lg"}
            p={{ base: 6, md: 8 }}
            mt={8}
            mx={{ base: 5, md: 0 }}
          >
            <HStack mb="5">
              <Button
                colorScheme="red"
                leftIcon={<FaGoogle />}
                onClick={async () => {
                  try {
                    await GoogleAuth();
                  } catch (e) {
                    if (e.code !== "auth/cancelled-popup-request")
                      setAuthError(ErrorHandlingMsg(e.code));
                  }
                }}
              >
                Google
              </Button>
              <Button
                colorScheme="facebook"
                leftIcon={<FaFacebook />}
                onClick={async () => {
                  try {
                    await FacebookAuth();
                  } catch (e) {
                    if (e.code !== "auth/cancelled-popup-request")
                      setAuthError(ErrorHandlingMsg(e.code));
                  }
                }}
              >
                Facebook
              </Button>
              <Button
                colorScheme="twitter"
                leftIcon={<FaTwitter />}
                onClick={async () => {
                  try {
                    await TwitterAuth();
                  } catch (e) {
                    if (e.code !== "auth/cancelled-popup-request")
                      setAuthError(ErrorHandlingMsg(e.code));
                  }
                }}
              >
                Twitter
              </Button>
            </HStack>

            <Text textAlign="center" color={"gray.500"}>
              OR
            </Text>
            <LoginUI setAuthError={setAuthError} />
          </Box>
          <Box
            rounded={"lg"}
            bg={colors[1]}
            boxShadow={"lg"}
            p={4}
            mt={{ base: 4, md: 8 }}
            mx={{ base: 5, md: 0 }}
            textAlign="center"
          >
            <Text>
              Don't have an account?
              <chakra.span
                color={"orange.900"}
                as={Link}
                to={"/signup"}
                className={"gagalin"}
              >
                {" "}
                Sign Up
              </chakra.span>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </motion.div>
  );
}

function LoginUI(props: any) {
  const toast = useToast();

  function validateEmail(value: any) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePassword(value: any) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  }
  const { EmailAuth, ErrorHandlingMsg, sendPasswordResetEmail } = useAuth();

  return (
    <Formik
      initialValues={{ email: "", password: "", auth: "" }}
      onSubmit={async (values: any, actions: any) => {
        try {
          await EmailAuth(values.email, values.password);
        } catch (error) {
          actions.setErrors({ auth: ErrorHandlingMsg(error.code) });
        }
        actions.setSubmitting(false);
      }}
    >
      {({ errors, touched, isValidating, isSubmitting, values }) => (
        <Form>
          <Stack spacing={4}>
            {errors.auth ? (
              <Text align="center" fontSize="sm" mt="2" color={"red.500"}>
                {errors.auth}
              </Text>
            ) : (
              ""
            )}
            <Field name="email" validate={validateEmail}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="Email"
                    autoComplete="username"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password" validate={validatePassword}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                >
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    {...field}
                    id="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <LinkUI
                  color={"orange.900"}
                  onClick={() => {
                    sendPasswordResetEmail(values.email)
                      .then(() => {
                        toast({
                          title: "Reset password has been successfully sent 👍",
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });
                      })
                      .catch((e: any) => {
                        props.setAuthError(ErrorHandlingMsg(e.code));
                      });
                  }}
                >
                  Forgot password?
                </LinkUI>
              </Stack>
              <Button
                mt={4}
                isLoading={isSubmitting}
                type="submit"
                variant={"outline"}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
