import React from "react";
import {
  Flex,
  Text,
  Icon,
  Spinner,
  Image,
  Heading,
  chakra,
  Divider,
  Box,
  useColorModeValue,
  Link as LinkUI,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FaExclamationTriangle } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import logoFlipped from "../../assets/images/logoflipped.png";
import Countdown from "react-countdown";

export const LoadingCard = () => {
  return (
    <Flex flexDir={"column"} align={"center"}>
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="orange.900"
        size="xl"
        my={6}
      />
      <Text fontSize={"xl"} textAlign={"center"}>
        Please Wait
      </Text>
    </Flex>
  );
};

export const ErrorCard = () => {
  return (
    <Flex flexDir={"column"} align={"center"}>
      <Icon
        as={FaExclamationTriangle}
        fontSize={"8rem"}
        my={4}
        color={"red.500"}
      />
      <Text fontSize={"xl"} textAlign={"center"}>
        Error occurred, please try again later. If the issue persists please{" "}
        <ClickableContact />,
      </Text>
    </Flex>
  );
};

export const PageHeader = ({ title, main, ...props }: any) => (
  <Flex alignSelf={"center"} {...props}>
    <Heading
      color={{ base: "orange.900", md: useColorModeValue("blue.900", "white") }}
    >
      <Image src={logo} w={{ base: 16, md: 20 }} display={"inline"} />
      <chakra.span
        color={"orange.900"}
        mr={2}
        display={{ base: "none", md: "inline" }}
      >
        {main ? main : "CryptoKnights"}
      </chakra.span>
      {title}
      <Image src={logoFlipped} w={{ base: 16, md: 20 }} display={"inline"} />
    </Heading>
  </Flex>
);

export const SectionHeader = ({
  title,
  divider = true,
  expiredOn = false,
  right = false,
  direction = "row",
  ...props
}: any) => (
  <Flex flexDir={"column"} {...props}>
    {divider && <Divider my={6} />}
    <Flex flexDir={{ base: direction, md: "row" }}>
      <Heading
        fontSize={{ base: "2xl", md: "3xl" }}
        color={"orange.900"}
        mb={{ base: 5, md: 10 }}
      >
        {title}
      </Heading>
      {expiredOn && <ExpiredOn value={expiredOn} />}
      {right && (
        <>
          <Flex ml={"auto"} display={{ base: "none", md: "block" }}>
            {right}
          </Flex>
        </>
      )}
    </Flex>
  </Flex>
);

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Text color={"red.500"}>Expired</Text>;
  } else {
    // Render a countdown
    return (
      <Text display={"inline"}>
        Expires In:{" "}
        <chakra.span color={"orange.900"}>
          {days}D {hours}H {minutes}M {seconds}S
        </chakra.span>
      </Text>
    );
  }
};

const ExpiredOn = ({ value }: any) => (
  <Flex ml={{ base: "", md: "auto" }} justify={{ base: "center", md: "" }}>
    {value && value.expiration && value.expiration.seconds ? (
      <Text variant={"Gagalin"}>
        <Countdown
          renderer={renderer}
          date={new Date(value.expiration.seconds * 1000)}
        />
      </Text>
    ) : value && value.expiration && !value.expiration.seconds ? (
      <Text variant={"Gagalin"}>
        Subscription <chakra.span color={"orange.900"}>Unlimited</chakra.span>
      </Text>
    ) : (
      <></>
    )}
  </Flex>
);

export const ClickableContact = () => {
  return (
    <LinkUI
      as={Link}
      to="./support"
      fontSize={"lg"}
      textDecoration={"underline"}
    >
      Contact Us
    </LinkUI>
  );
};

export const DashCard = ({
  title,
  icon,
  padding,
  page,
  comingSoon,
  query,
}: any) => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "blue.900")}
      rounded={"lg"}
      position={"relative"}
      overflow={"hidden"}
      display={"flex"}
      shadow={"lg"}
    >
      <Box
        display={"flex"}
        w={"100%"}
        h="10rem"
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        shadow={"lg"}
        background={useColorModeValue(
          "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(245, 176, 2, .15)), to(rgb(245, 176, 2))), -webkit-gradient(linear, right top, left bottom, color-stop(0, rgba(245, 176, 2, 0)), color-stop(0.5, rgba(245, 176, 2, .1)), color-stop(0.501, rgba(245, 176, 2, 0)), color-stop(1, rgba(245, 176, 2, 0)));",
          "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, .15)), to(rgb(13 12 29))), -webkit-gradient(linear, right top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(0.5, rgba(255, 255, 255, .1)), color-stop(0.501, rgba(255, 255, 255, 0)), color-stop(1, rgba(255, 255, 255, 0)));"
        )}
        color={"orange.900"}
        _hover={{
          cursor: "pointer",
          color: "white",
          background: useColorModeValue(
            "-webkit-gradient(linear, 0% 0%, 0% 50%, from(rgba(245, 176, 2, .15)), to(rgb(245, 176, 2))), -webkit-gradient(linear, right top, left bottom, color-stop(0, rgba(245, 176, 2, 0)), color-stop(0.5, rgba(245, 176, 2, .1)), color-stop(0.501, rgba(245, 176, 2, 0)), color-stop(1, rgba(245, 176, 2, 0)));",
            "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(245, 176, 2, .15)), to(rgb(245, 176, 2))), -webkit-gradient(linear, right top, left bottom, color-stop(0, rgba(245, 176, 2, 0)), color-stop(0.5, rgba(245, 176, 2, .1)), color-stop(0.501, rgba(245, 176, 2, 0)), color-stop(1, rgba(245, 176, 2, 0)));"
          ),
        }}
        as={!page ? Box : Link}
        to={!query ? "/" + page : { pathname: `/${page}`, query: query }}
      >
        {comingSoon && (
          <Flex className={"ribbon ribbon-top-right"}>
            <span>Coming Soon</span>
          </Flex>
        )}

        <Flex flexDir={"column"}>
          <Icon
            as={icon}
            w={"auto"}
            h={{ base: "5rem", md: "7rem" }}
            padding={padding ? { base: 4, md: 5 } : 0}
            color={"white"}
          />
          <Heading
            textShadow={"lg"}
            zIndex={10}
            fontSize={{ base: "xl", md: "3xl" }}
            color={useColorModeValue("white", "orange.900")}
          >
            {title}
          </Heading>
        </Flex>
      </Box>
    </Box>
  );
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
