import metamaskLogo from "../assets/images/metamask.png";
import coinbaseLogo from "../assets/images/coinbase.svg";
import ercLogo from "../assets/images/erc.png";
import bscLogo from "../assets/images/bsc.png";

export const paymentAddress = "0x410795eCc0ea30Cf08402E48084ec59A9CBB69Ec";

export const chains = {
  Binance: {
    id: 56,
    contracts: {
      ETH: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
      BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      USDT: "0x55d398326f99059ff775485246999027b3197955",
      USDC: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      DAI: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    },
    blockchain: "https://bscscan.com/",
    logo: bscLogo,
  },
  Ethereum: {
    id: 1,
    contracts: {
      BUSD: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      BNB: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
    },
    blockchain: "https://etherscan.io/",
    logo: ercLogo,
  },
  Ropsten: {
    id: 3,
    contracts: {
      BUSD: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      BNB: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
    },
    blockchain: "https://ropsten.etherscan.io/",
    logo: ercLogo,
  },
};

export interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  pendingRequest: boolean;
  result: any | null;
}

export const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  pendingRequest: false,
  result: null,
};

export const currencies = [
  {
    symbol: "BTC",
    icon: "btc",
    method: "coinbase",
  },
  {
    symbol: "ETH",
    icon: "eth",
    method: "both",
  },
  {
    symbol: "BNB",
    icon: "bnb",
    method: "metamask",
  },
  {
    symbol: "BUSD",
    icon: "busd",
    method: "metamask",
  },
  {
    symbol: "USDT",
    icon: "usdt",
    method: "metamask",
  },
  {
    symbol: "USDC",
    icon: "usdc",
    method: "both",
  },
  {
    symbol: "LTC",
    icon: "ltc",
    method: "coinbase",
  },
  {
    symbol: "DAI",
    icon: "dai",
    method: "both",
  },
  {
    symbol: "BCH",
    icon: "btc",
    method: "coinbase",
  },
];

export const methods = [
  {
    name: "metamask",
    icon: metamaskLogo,
  },
  {
    name: "coinbase",
    icon: coinbaseLogo,
  },
];

export const periods = [
  {
    name: "12 Months",
    price: 360,
    value: 12,
    coinbase: "f2609fb1-31e0-404c-89b1-cc9aabc3cd8c",
    admin: false,
  },
  {
    name: "6 Months",
    price: 240,
    value: 6,
    coinbase: "55bdc22e-0a7a-4f39-80ef-0c81522eecf0",
    admin: false,
  },
  {
    name: "3 Months",
    price: 150,
    value: 3,
    coinbase: "c5c8e3a4-a783-40cc-99ca-0350f9c23c92",
    admin: false,
  },
  {
    name: "1 Months",
    price: 5,
    value: 1,
    coinbase: "81c7f0c6-3252-47c1-9270-c98831ded4c4",
    admin: true,
  },
];
