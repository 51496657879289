import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stat,
  StatLabel,
  StatNumber,
  Input,
  useClipboard,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  chakra,
  ModalFooter,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import {
  PageHeader,
  SectionHeader,
  ErrorCard,
  LoadingCard,
  ClickableContact,
} from "../components/helpers/Helpers";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import TimeAgo from "react-timeago";

export default function Referrals(props: any) {
  const { user } = useAuth();

  const referralRef = firebase
    .firestore()
    .collection("referrals")
    .doc(user.uid);

  const [value, loading, error] = useDocumentData(referralRef);
  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Referrals"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
          fontFamily={"Gagalin"}
        >
          {value && value.username && <ReferralsView user={value} />}
          {!value && !error && !loading && <RegisterReferralLink />}
          {error && <ErrorCard />}
          {loading && <LoadingCard />}
        </Box>
        {value && value.username && <ReferredUsersTable user={value} />}
      </Flex>
    </Flex>
  );
}

function RegisterReferralLink() {
  const toast = useToast();
  const { user } = useAuth();

  function validateUsername(value) {
    let error;
    if (!value) {
      error = "Username is required";
    } else if (value && (value.length < 4 || value.length > 15)) {
      error = "Username is invalid";
    }
    return error;
  }

  return (
    <Flex
      mx={"auto"}
      justifyContent={"center"}
      w={"50%"}
      flexDir={"column"}
      textAlign={"center"}
    >
      <SectionHeader title={"How it works"} divider={false} />
      <Text fontSize={{ base: "md", md: "xl" }} textAlign={"center"}>
        Refer your friends and earn credits redeeming them for a CryptoKnights
        subscription
      </Text>
      <SectionHeader title={"Register Custom Referral Link"} />
      <Formik
        initialValues={{ username: "" }}
        onSubmit={async (values: any, actions: any) => {
          await firebase
            .firestore()
            .collection("referrals")
            .doc(user.uid)
            .set({
              username: values.username,
              usernameLower: values.username.toLowerCase(),
              credit: 0,
            })
            .then(() => {
              toast({
                title: "Referral link created 👍",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            })
            .catch((err) => {
              toast({
                title: (
                  <Text textAlign={"center"}>
                    Error occurred, please try again later. If the issue
                    persists please <ClickableContact />
                  </Text>
                ),
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={4}>
              <Text>Between 4 and 15 characters</Text>
              <Field name="username" validate={validateUsername}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.username && form.touched.username}
                  >
                    <Input
                      {...field}
                      id="username"
                      placeholder="Referral Username"
                    />
                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                variant="outline"
                isLoading={isSubmitting}
                type="submit"
              >
                Save
              </Button>
              <Text textAlign={"center"} mt={4}>
                Note: The username is not changeable
              </Text>
            </Stack>
          </Form>
        )}
      </Formik>
    </Flex>
  );
}

function ReferralsView({ user }: any) {
  const [redeem, setRedeem] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { hasCopied, onCopy } = useClipboard(
    `https://thecryptoknights.io/r/${user.username}`
  );
  return (
    <>
      <SectionHeader title={"How it works"} divider={false} />
      <Text fontSize={{ base: "md", md: "xl" }} textAlign={"center"}>
        Refer your friends and earn credits redeeming them for a CryptoKnights
        subscription
      </Text>
      <Flex flexDir={"column"} mt={5} justifyContent={"center"}>
        <Text fontSize={"xl"} textAlign={"center"} color={"orange.900"}>
          Your referral link
        </Text>
        <Flex mb={2} justifyContent={"center"}>
          <Input
            maxW={{ base: "full", md: "25%" }}
            value={`thecryptoknights.io/r/${user.username}`}
            isReadOnly
            placeholder="Username"
          />
          <Button onClick={onCopy} ml={2} variant={"outline"}>
            {hasCopied ? "Copied" : "Copy"}
          </Button>
        </Flex>
      </Flex>

      <SectionHeader title={"Redeem"} />
      <Stat textAlign={"center"} mt={"-5"}>
        <StatLabel fontSize={"lg"}>Current Balance</StatLabel>
        <StatNumber color={"orange.900"} fontSize={"3xl"}>
          {user.credit} Credits
        </StatNumber>
      </Stat>
      <Flex
        justifyContent={"center"}
        mt={5}
        flexDir={{ base: "column", md: "row" }}
      >
        <Button
          variant={"outline"}
          m={2}
          h={"5rem"}
          onClick={() => {
            setRedeem(30);
            onOpen();
          }}
        >
          <Flex flexDir={"column"}>
            3 Months Subscription
            <Text fontSize={"md"} my={2}>
              (30 Credits)
            </Text>
          </Flex>
        </Button>
        <Button
          variant={"outline"}
          m={2}
          h={"5rem"}
          onClick={() => {
            setRedeem(60);
            onOpen();
          }}
        >
          <Flex flexDir={"column"}>
            6 Months Subscription
            <Text fontSize={"md"} my={2}>
              (60 Credits)
            </Text>
          </Flex>
        </Button>
        <Button
          variant={"outline"}
          m={2}
          h={"5rem"}
          onClick={() => {
            setRedeem(120);
            onOpen();
          }}
        >
          <Flex flexDir={"column"}>
            12 Months Subscription
            <Text fontSize={"md"} my={2}>
              (120 Credits)
            </Text>
          </Flex>
        </Button>
      </Flex>
      {redeem && (
        <ConfirmModal credit={redeem} onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
}

function ConfirmModal({ credit, onClose, isOpen }: any) {
  const { user } = useAuth();
  const toast = useToast();
  async function RedeemCredit() {
    await firebase
      .firestore()
      .collection("referrals")
      .doc(user.uid)
      .set(
        {
          credit: firebase.firestore.FieldValue.increment(-credit),
        },
        { merge: true }
      )
      .then(() => {
        toast({
          title: "Credit redeemed successfully 👍",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        if (err.code === "permission-denied")
          toast({
            title: "Insufficient funds",
            status: "info",
            duration: 9000,
            isClosable: true,
          });
        else
          toast({
            title: (
              <Text textAlign={"center"}>
                Error occurred, please try again later. If the issue persists
                please <ClickableContact />
              </Text>
            ),
            status: "error",
            duration: 9000,
            isClosable: true,
          });
      });
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"md"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>Redemption Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign={"center"} fontSize={"lg"}>
            Are you sure you want to redeem{" "}
            <chakra.b color={"orange.900"}>{credit} credits</chakra.b> for a{" "}
            <chakra.b color={"orange.900"}>{credit / 10} months</chakra.b>{" "}
            subscription?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant={"outline"} mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outline" onClick={async () => RedeemCredit()}>
            Redeem
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ReferredUsersTable({ user }: any) {
  return (
    <Box
      w={"full"}
      bg={useColorModeValue("white", "gray.800")}
      rounded={"lg"}
      boxShadow={"lg"}
      p={6}
      mt={10}
      fontFamily={"Gagalin"}
    >
      <SectionHeader title={"Referred Users"} divider={false} />
      {user.referred && user.referred.length > 0 && (
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th fontSize={"xl"}>Email Address</Th>
                <Th fontSize={"xl"}>Subscription</Th>
                <Th fontSize={"xl"}>Credits Earned</Th>
                <Th fontSize={"xl"}>Earned On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {user.referred
                .slice(0)
                .reverse()
                .map((referred, index) => (
                  <Tr key={index}>
                    <Td>{referred.email}</Td>
                    <Td>{referred.duration} Months</Td>
                    <Td>{referred.credit}</Td>
                    <Td>
                      <TimeAgo
                        date={new Date(referred.earnedOn.seconds * 1000)}
                      />
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {(!user.referred || user.referred?.length === 0) && (
        <Text textAlign={"center"} fontSize={"lg"}>
          You currently do not have any referred users
        </Text>
      )}
    </Box>
  );
}
