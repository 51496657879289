import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function PrivateRoute({ component: Component, ...rest }: any) {
  const { user } = useAuth();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{rest.title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          return user ? <Component {...props} /> : <Redirect to="/signin" />;
        }}
      ></Route>
    </>
  );
}
