import React, { useState } from "react";
import { Box, useColorModeValue, Select, Flex } from "@chakra-ui/react";
import {
  ErrorCard,
  LoadingCard,
  PageHeader,
} from "../components/helpers/Helpers";
import {
  GetLastMonthSelected,
  GetStats,
} from "../components/statistics/statistics";

import ScrollToTopOnMount from "../services/scrollToTop";

import firebase from "../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { motion } from "framer-motion";

const containerVariants2 = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: 0,
    transition: { ease: "easeInOut", duration: 1.5 },
  },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

export default function Statistics(props: any) {
  const userRef = firebase.firestore().collection("statistics").doc("Months");
  const [value, loading, error] = useDocumentData(userRef);
  const [selectedValue, setSelectedValue] = useState(GetLastMonthSelected);
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={props.location.query?.noTransition ? {} : containerVariants2}
      exit="exit"
    >
      <ScrollToTopOnMount />
      <Flex
        minH="96vh"
        justify={"center"}
        bg={useColorModeValue("gray.50", "blue.900")}
      >
        <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
          <PageHeader title={"Statistics"} />
          <Box
            w={"full"}
            bg={useColorModeValue("white", "gray.800")}
            rounded={"lg"}
            boxShadow={"md"}
            p={6}
            mt={10}
          >
            {value && (
              <Select
                onChange={(e) => setSelectedValue(e.currentTarget.value)}
                defaultValue={selectedValue}
                _hover={{ cursor: "pointer" }}
              >
                {value.dates.map((child: string) => {
                  const data = child.replace(/\s/g, "").toLowerCase();
                  return (
                    <option key={data} value={data}>
                      {child}
                    </option>
                  );
                })}
              </Select>
            )}
            {error && <ErrorCard />}
            {loading && <LoadingCard />}
          </Box>
          <Box
            w={"full"}
            bg={useColorModeValue("white", "gray.800")}
            rounded={"lg"}
            boxShadow={"lg"}
            p={6}
            mt={4}
          >
            {selectedValue && <GetStats month={selectedValue} />}
          </Box>
        </Flex>
      </Flex>
    </motion.div>
  );
}
