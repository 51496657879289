import { FaSuitcase } from "react-icons/fa";

const items = {
  all: {
    name: "All Assets",
    value: "all",
    image: { type: "icon", url: FaSuitcase },
  },
  metamask: {
    name: "MetaMask",
    value: "metamask",
    image: { type: "image", url: "metamask.png" },
  },
};

export default items;
