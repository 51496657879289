// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import queryString from "query-string";

import firebase from "../../services/firebase";
import "firebase/auth";

import { isMobile } from "react-device-detect";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function ProvideAuth({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isAdmin, setAdmin] = useState(null);
  const lastLocation = useLastLocation();
  const currentLocation = useLocation();

  const EmailAuth = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        if (lastLocation.pathname === "/profile") {
          history.push(lastLocation.pathname + lastLocation.search);
        } else history.push("/dashboard");
      });
  };

  const CheckAdmin = async () => {
    return await firebase
      .firestore()
      .collection("admins")
      .doc("ADMINS")
      .get()
      .then(() => setAdmin(true))
      .catch(() => setAdmin(false));
  };

  const GoogleAuth = (
    signUp = false,
    inviteLink = false,
    referralID = false
  ) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    if (isMobile)
      return firebase
        .auth()
        .signInWithRedirect(provider)
        .catch((err) => alert(err));
    return ProviderSignInPopUp(provider, signUp, inviteLink, referralID);
  };

  const FacebookAuth = (
    signUp = false,
    inviteLink = false,
    referralID = false
  ) => {
    var provider = new firebase.auth.FacebookAuthProvider();
    if (isMobile) return firebase.auth().signInWithRedirect(provider);
    return ProviderSignInPopUp(provider, signUp, inviteLink, referralID);
  };

  const TwitterAuth = (
    signUp = false,
    inviteLink = false,
    referralID = false
  ) => {
    var provider = new firebase.auth.TwitterAuthProvider();
    if (isMobile) return firebase.auth().signInWithRedirect(provider);
    return ProviderSignInPopUp(provider, signUp, inviteLink, referralID);
  };

  const ProviderSignInPopUp = (provider, signUp, inviteLink, referralID) => {
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (response) => {
        setUser(response.user);
        if (response.additionalUserInfo.isNewUser) {
          await SignUpChecker(response.user.uid, inviteLink, referralID);
          history.push({
            pathname: "/profile",
            state: { signUp: true },
          });
        } else if (lastLocation.pathname === "/profile") {
          history.push(lastLocation.pathname + lastLocation.search);
        } else history.push("/dashboard");
      });
  };

  const SignUp = (email, password, inviteLink = false, referralID = false) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        setUser(response.user);
        await SignUpChecker(response.user.uid, inviteLink, referralID);
        history.push({
          pathname: "/profile",
          state: { signUp: true },
        });
      });
  };

  const SignUpChecker = async (uid, inviteLink, referralID) => {
    if (inviteLink) {
      return await firebase
        .firestore()
        .collection("invite_pending")
        .doc(uid)
        .set({
          invite: inviteLink,
        });
    } else if (referralID) {
      return await firebase
        .firestore()
        .collection("referral_pending")
        .doc(uid)
        .set({
          invite: referralID,
        });
    }
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const ErrorHandlingMsg = (code) => {
    if (code === "auth/invalid-email") return "This email address is invalid";
    else if (code === "auth/user-disabled")
      return "This account has been disabled";
    else if (code === "auth/user-not-found")
      return "Invalid email or password, please try again";
    else if (code === "auth/wrong-password")
      return "Invalid email or password, please try again";
    else if (code === "auth/email-already-in-use")
      return "This email address is already in use";
    else if (code === "auth/invalid-email")
      return "This email address is incorrect";
    else if (code === "auth/weak-password")
      return "Please input a stronger password";
    else if (code === "auth/account-exists-with-different-credential")
      return "This account is associated with a different login provider";
    else if (code === "auth/popup-blocked")
      return "Please allow browser popups";
    else if (code === "auth/popup-closed-by-user")
      return "The popup has been closed, please try again";
    else return "An error occurred, please try again later";
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (isMobile) {
          const profileCode = new URLSearchParams(window.location.search).get(
            "code"
          );
          await firebase
            .auth()
            .getRedirectResult()
            .then(async (redirectUser) => {
              if (
                redirectUser.user &&
                redirectUser.additionalUserInfo.isNewUser
              ) {
                let inviteLink = false;
                let referralID = false;

                if (currentLocation.pathname.split("/")[1] === "r")
                  referralID = currentLocation.pathname.split("/")[2];
                else if (currentLocation.pathname.split("/")[1] === "invite")
                  inviteLink = currentLocation.pathname.split("/")[2];

                await SignUpChecker(
                  redirectUser.user.uid,
                  inviteLink,
                  referralID
                );
                history.push({
                  pathname: "/profile",
                  state: { signUp: true },
                });
              } else if (lastLocation && lastLocation.pathname === "/profile") {
                history.push(
                  lastLocation && lastLocation.pathname + lastLocation.search
                );
                // Mobile Discord
              } else if (profileCode) {
                history.push("profile?code=" + profileCode);
              } else history.push("/dashboard");
            });
        }
        setUser(user);
        CheckAdmin();
      } else {
        setUser(null);
        setAdmin(null);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const value = {
    user,
    isAdmin,
    EmailAuth,
    GoogleAuth,
    FacebookAuth,
    TwitterAuth,
    SignUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    ErrorHandlingMsg,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
