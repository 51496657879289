import React from "react";
import { Switch, useLocation } from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { Fonts } from "./components/Fonts/Fonts";

import NavBar from "./components/navbar/navbar";
import { ProvideAuth } from "./components/auth/useAuth";

import FrontPage from "./pages/FrontPage";
import Terms from "./pages/Terms";
import SignIn from "./pages/SignIn";
import Invite from "./pages/Invite";
import Profile from "./pages/Profile";
import Statistics from "./pages/Statistics";
import SignUp from "./pages/SignUp";
import Subscriptions from "./pages/Subscriptions";
import FAQ from "./pages/FAQ";
import Dashboard from "./pages/Dashboard";
import Resources from "./pages/Resources";
import Referrals from "./pages/Referrals";
import Referral from "./pages/Referral";
import Support from "./pages/Support";
import Test from "./pages/Test";

import Portfolio from "./pages/Portfolio";

import AdminDashboard from "./pages/admin/Dashboard";
import AdminStatistics from "./pages/admin/Statistics";
import AdminUsers from "./pages/admin/Users";
import AdminInvites from "./pages/admin/Invites";
import AdminFAQ from "./pages/admin/FAQ";
import AdminResources from "./pages/admin/Resources";

import OpenRoute from "./components/auth/openRoute";
import PrivateRoute from "./components/auth/privateRoute";
import AdminRoute from "./components/auth/adminRoute";

import { AnimatePresence } from "framer-motion";
import theme from "./services/theme";

export const App = () => {
  const location = useLocation();
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <ProvideAuth>
        <NavBar />
        <Box mt={"60px"}>
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <OpenRoute path="/signin" component={SignIn} title={"Sign In"} />
              <OpenRoute path="/signup" component={SignUp} title={"Sign Up"} />
              <OpenRoute
                path="/invite/:inviteID"
                component={Invite}
                title={"Invitation"}
              />
              <OpenRoute
                path="/r/:referralID"
                component={Referral}
                title={"Referral"}
              />
              <OpenRoute
                path="/statistics"
                component={Statistics}
                title={"Statistics"}
              />
              <OpenRoute
                path="/terms"
                component={Terms}
                title={"Terms & Conditions"}
              />

              <PrivateRoute
                path="/profile"
                component={Profile}
                title={"Profile"}
              />
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
                title={"Dashboard"}
              />
              <PrivateRoute
                path="/subscriptions"
                component={Subscriptions}
                title={"Subscriptions"}
              />
              <PrivateRoute path="/FAQ" component={FAQ} title={"FAQ"} />
              <PrivateRoute
                path="/resources"
                component={Resources}
                title={"Resources"}
              />
              <PrivateRoute
                path="/referrals"
                component={Referrals}
                title={"Referrals"}
              />

              <PrivateRoute
                path="/support"
                component={Support}
                title={"Support"}
              />

              <AdminRoute
                path="/portfolio"
                exact
                component={Portfolio}
                title={"Portfolio"}
              />

              <AdminRoute
                path="/admin"
                exact
                component={AdminDashboard}
                title={"Dashboard"}
              />

              <AdminRoute
                path="/admin/statistics"
                component={AdminStatistics}
                title={"Statistics"}
              />
              <AdminRoute
                path="/admin/users"
                component={AdminUsers}
                title={"Users"}
              />
              <AdminRoute
                path="/admin/invites"
                component={AdminInvites}
                title={"Invites"}
              />
              <AdminRoute
                path="/admin/FAQ"
                component={AdminFAQ}
                title={"FAQ"}
              />
              <AdminRoute
                path="/admin/resources"
                component={AdminResources}
                title={"Resources"}
              />

              <AdminRoute
                path={"/admin/test"}
                component={Test}
                title={"testing"}
              />

              <OpenRoute
                exact
                path="*"
                component={FrontPage}
                title={"CryptoKnights"}
              />
            </Switch>
          </AnimatePresence>
        </Box>
      </ProvideAuth>
    </ChakraProvider>
  );
};
