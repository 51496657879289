import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  SimpleGrid,
  Stack,
  Heading,
  FormControl,
  Input,
  Select,
  FormLabel,
  HStack,
  useToast,
  Divider,
  RadioGroup,
  Radio,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  chakra,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import {
  PageHeader,
  ErrorCard,
  LoadingCard,
} from "../../components/helpers/Helpers";

import firebase from "../../services/firebase";
import "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import DataTable from "../../components/admin/statistics/table";

import {
  FaArrowDown,
  FaArrowUp,
  FaCoins,
  FaDiscord,
  FaInfinity,
  FaSearch,
  FaTags,
  FaTelegram,
  FaTrash,
} from "react-icons/fa";
import TimeAgo from "react-timeago";

import PaymentsView from "../../components/profile/payments";
import { SocialUsername, SocialIcon } from "../../components/profile/accounts";
import { Formik, Form, Field } from "formik";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/admin/users/date-picker.css";

export default function AdminUsers(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openModal, setOpenModal] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [search, setSearch] = useState<any>(null);

  const [direction, setDirection] = useState<any>("desc");
  const [order, setOrder] = useState("joined");
  const pageLimit = 10;

  let usersRef;
  if (search && search.result && search.field) {
    let searchResult;
    if (
      search.field === "accounts.telegram.username" ||
      search.field === "accounts.discord.username"
    )
      searchResult = search.result;
    else searchResult = search.result.toLowerCase();
    usersRef = firebase
      .firestore()
      .collection("users")
      .where(search.field, "==", searchResult);
  } else if (search && search.pagination === "next") {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .startAfter(search.lastVisible)
      .limit(pageLimit);
  } else if (search && search.pagination === "back") {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .startAt(search.back)
      .limit(pageLimit);
  } else {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .limit(pageLimit);
  }

  const [values, loading, error] = useCollectionData(usersRef, {
    idField: "uid",
    refField: "ref",
  });

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Users"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"md"}
          p={6}
          mt={10}
        >
          <Flex
            align={"center"}
            w={"full"}
            justify={"center"}
            flexDir={{ base: "column", md: "row" }}
          >
            <SearchBar setSearch={setSearch} />
            <FilterUI
              order={order}
              direction={direction}
              setDirection={setDirection}
              setOrder={setOrder}
              setSearch={setSearch}
            />
          </Flex>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {values && (
            <DataTable
              columns={columnHeaders(setOpenModal, setCurrentUser, onOpen)}
              data={values}
            />
          )}
          <Flex justifyContent={"center"} mt={6}>
            {values && search && search.counter ? (
              <Button
                mx={2}
                variant={"outline"}
                onClick={() => {
                  setSearch({
                    lastVisible: values.length
                      ? values[values.length - 1][order]
                      : search.firstVisible[search.counter - 1],
                    firstVisible: search.firstVisible,
                    back: search.firstVisible[search.counter - 1],
                    pagination: "back",
                    counter: search.counter - 1,
                  });
                }}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
            {values && values.length > 0 && (
              <Button
                mx={2}
                variant={"outline"}
                onClick={() => {
                  setSearch({
                    lastVisible: values[values.length - 1][order],
                    firstVisible: search?.firstVisible
                      ? [...search?.firstVisible, values[0][order]]
                      : [values[0][order]],
                    pagination: "next",
                    counter: search?.counter ? search.counter + 1 : 1,
                  });
                }}
              >
                Next
              </Button>
            )}
          </Flex>
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
          {openModal === "Payments" && currentUser && (
            <ViewPayments uid={currentUser} onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "Delete" && currentUser && (
            <DeleteAccount
              uid={currentUser}
              onClose={onClose}
              isOpen={isOpen}
            />
          )}
          {openModal === "Subscription" && currentUser && (
            <ViewSubscription
              data={currentUser}
              onClose={onClose}
              isOpen={isOpen}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const columnHeaders = (setOpenModal: any, setCurrentUser: any, onOpen: any) => [
  {
    Header: "Full Name",
    accessor: "fullName",
    Cell: (row) => {
      const data = row.row.original;
      if (!data.first_name && !data.first_name) return <Text>-</Text>;
      else
        return (
          <Text>
            {(data.first_name || "-") + " " + (data.last_name || "-")}
          </Text>
        );
    },
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Date Joined",
    accessor: "joined",
    Cell: (row) => {
      const data = row.row.original;
      return <TimeAgo date={new Date(data.joined.seconds * 1000)} />;
    },
  },
  {
    Header: "Expiration",
    accessor: "expiration",
    Cell: (row) => {
      const data = row.row.original;
      if (data.expiration && data.expiration.seconds)
        return <TimeAgo date={new Date(data.expiration.seconds * 1000)} />;
      else if (data.expiration === "unlimited")
        return <Icon color={"orange.900"} as={FaInfinity} />;
      else return "-";
    },
  },
  {
    Header: "Subscription",
    accessor: "subscription",
    Cell: (row) => {
      const data = row.row.original;
      return (
        <Text color={data.subscription === "active" ? "green.500" : "red.500"}>
          {data.subscription === "active" ? "Active" : "Inactive"}
        </Text>
      );
    },
  },
  {
    Header: "Actions",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Flex>
          <IconButton
            icon={<FaTags />}
            aria-label="Subscription"
            mr={1}
            variant={"outline"}
            onClick={() => {
              setOpenModal("Subscription");
              setCurrentUser(data);
              onOpen();
            }}
          />
          <IconButton
            icon={<FaCoins />}
            aria-label="Payments"
            variant={"outline"}
            mr={1}
            onClick={() => {
              setOpenModal("Payments");
              setCurrentUser(data.uid);
              onOpen();
            }}
          />
          <IconButton
            icon={<FaTrash />}
            aria-label="Delete"
            mr={1}
            variant={"delete"}
            onClick={() => {
              setOpenModal("Delete");
              setCurrentUser(data.uid);
              onOpen();
            }}
          />
        </Flex>
      );
    },
  },
];

function ViewPayments({ uid, onClose, isOpen }: any) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={"6xl"}
      scrollBehavior={"outside"}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaCoins} mr={2} />
          Payments
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted>
            <TabList>
              <Tab
                _selected={{
                  color: "orange.900",
                  borderColor: "currentColor",
                }}
              >
                Payments
              </Tab>
              <Tab
                _selected={{
                  color: "orange.900",
                  borderColor: "currentColor",
                }}
              >
                Referrals
              </Tab>
            </TabList>
            <TabPanels mt={5}>
              <TabPanel>
                <PaymentsView uid={uid} />
              </TabPanel>
              <TabPanel>
                <ReferredUsersTable uid={uid} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="outline">
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ReferredUsersTable({ uid }: any) {
  const subscriptionRef = firebase.firestore().collection("referrals").doc(uid);

  const [value, loading, error] = useDocumentData(subscriptionRef);
  return (
    <>
      {loading && <LoadingCard />}
      {error && <ErrorCard />}
      {value && value.referred && value.referred.length > 0 && (
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th fontSize={"xl"}>Email Address</Th>
                <Th fontSize={"xl"}>Subscription</Th>
                <Th fontSize={"xl"}>Credits Earned</Th>
                <Th fontSize={"xl"}>Earned</Th>
              </Tr>
            </Thead>
            <Tbody>
              {value.referred.map((referred, index) => (
                <Tr key={index}>
                  <Td>{referred.email}</Td>
                  <Td>{referred.duration} Months</Td>
                  <Td>{referred.credit}</Td>
                  <Td>
                    <TimeAgo
                      date={new Date(referred.earnedOn.seconds * 1000)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {value && (!value.referred || value.referred?.length === 0) && (
        <Text textAlign={"center"} fontSize={"lg"}>
          The user has no referrals
        </Text>
      )}
    </>
  );
}

function ViewSubscription({ data, onClose, isOpen }: any) {
  const subscriptionRef = firebase
    .firestore()
    .collection("subscriptions")
    .doc(data.uid);

  const toast = useToast();
  const [value, loading, error] = useDocumentData(subscriptionRef);

  let initValues;

  if (value) {
    initValues = {
      status: value.status,
      expiration: value.expiration?.seconds
        ? new Date(value.expiration.seconds * 1000)
        : new Date(),
      term: value.expiration === "unlimited" ? "1" : "0",
    };
  } else {
    initValues = {
      status: "inactive",
      expiration: new Date(),
      term: "0",
    };
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTags} mr={2} />
          Subscription
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!loading && (
            <Tabs isFitted>
              <TabList>
                <Tab
                  _selected={{
                    color: "orange.900",
                    borderColor: "currentColor",
                  }}
                >
                  Subscription
                </Tab>
                <Tab
                  _selected={{
                    color: "orange.900",
                    borderColor: "currentColor",
                  }}
                >
                  Accounts
                </Tab>
              </TabList>
              <TabPanels mt={5}>
                <TabPanel>
                  <Formik
                    initialValues={initValues}
                    onSubmit={async (values: any, actions: any) => {
                      let submitData;
                      const exp =
                        values.term === "1"
                          ? "unlimited"
                          : firebase.firestore.Timestamp.fromDate(
                              values.expiration
                            );
                      if (values.status === "active") {
                        submitData = {
                          status: "active",
                          started:
                            firebase.firestore.FieldValue.serverTimestamp(),
                          expiration: exp,
                          plan: "Custom",
                          reminder: false,
                        };
                      } else {
                        submitData = {
                          status: "inactive",
                          expiration: firebase.firestore.FieldValue.delete(),
                          started: firebase.firestore.FieldValue.delete(),
                          plan: firebase.firestore.FieldValue.delete(),
                          reminder: false,
                        };
                      }
                      await firebase
                        .firestore()
                        .collection("subscriptions")
                        .doc(data.uid)
                        .set(submitData, { merge: true })
                        .then(() => {
                          toast({
                            title: "Updated Successfully 👍",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        })
                        .catch((err) => {
                          toast({
                            title: "Error Occurred ⚠️",
                            description: err.message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        });
                      actions.setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <Stack spacing={4}>
                          <Field name="status">
                            {({ field, form }: any) => (
                              <FormControl>
                                <FormLabel htmlFor="customName">
                                  <Heading color={"orange.900"} fontSize={"xl"}>
                                    Status
                                  </Heading>
                                </FormLabel>
                                <Select {...field} id="status">
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                          {values.status === "active" && (
                            <Field name="term">
                              {({ field, form }: any) => (
                                <FormControl>
                                  <FormLabel htmlFor="term">Term</FormLabel>
                                  <RadioGroup {...field} id="term">
                                    <HStack spacing="24px" align={"center"}>
                                      <Radio {...field} value={"0"}>
                                        Predefined
                                      </Radio>
                                      <Radio {...field} value={"1"}>
                                        Unlimited
                                      </Radio>
                                    </HStack>
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </Field>
                          )}
                          {values.term === "0" && values.status === "active" && (
                            <Field name="expiration">
                              {({ field, form }: any) => (
                                <FormControl>
                                  <FormLabel htmlFor="expiration">
                                    <Heading
                                      color={"orange.900"}
                                      fontSize={"xl"}
                                    >
                                      Expiration Date
                                    </Heading>
                                  </FormLabel>
                                  <DatePicker
                                    minDate={new Date()}
                                    showPopperArrow={false}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={(d) => {
                                      setFieldValue("expiration", d);
                                    }}
                                    selected={field.value}
                                  />
                                </FormControl>
                              )}
                            </Field>
                          )}
                          {value && value.started && (
                            <Flex alignItems={"center"}>
                              <Heading
                                color={"orange.900"}
                                fontSize={"xl"}
                                mr={5}
                              >
                                Started
                              </Heading>
                              <TimeAgo
                                date={new Date(value.started?.seconds * 1000)}
                              />
                              <Text fontSize={"sm"} ml={2}>
                                (
                                {new Date(value.started?.seconds * 1000)
                                  .toISOString()
                                  .slice(0, 10)}
                                )
                              </Text>
                            </Flex>
                          )}
                        </Stack>
                        <HStack mt={10} mb={6}>
                          <Button
                            isFullWidth
                            variant="outline"
                            isLoading={isSubmitting}
                            type="submit"
                          >
                            Save
                          </Button>
                          <Button
                            isFullWidth
                            onClick={onClose}
                            variant="outline"
                          >
                            Return
                          </Button>
                        </HStack>
                      </Form>
                    )}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    spacing={2}
                    verticalAlign={"center"}
                  >
                    <Stack align={"center"} p={"5"}>
                      <SocialIcon
                        icon={FaTelegram}
                        text="Telegram"
                        color={"#0088CC"}
                      />
                      {data?.accounts?.telegram ? (
                        <SocialUsername
                          text={data?.accounts?.telegram?.username}
                        />
                      ) : (
                        <Text
                          color={"red.500"}
                          fontWeight={600}
                          fontSize={"lg"}
                        >
                          No Account
                        </Text>
                      )}
                    </Stack>
                    <Stack align={"center"} p={"5"}>
                      <SocialIcon
                        icon={FaDiscord}
                        text="Discord"
                        color={"#7289DA"}
                      />
                      {data?.accounts?.discord ? (
                        <SocialUsername
                          text={data?.accounts?.discord?.username}
                        />
                      ) : (
                        <Text
                          color={"red.500"}
                          fontWeight={600}
                          fontSize={"lg"}
                        >
                          No Account
                        </Text>
                      )}
                    </Stack>
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function DeleteAccount({ uid, onClose, isOpen }: any) {
  const toast = useToast();

  const deleteUser = async (uid) => {
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .delete()
      .then(() => {
        toast({
          title: "User deleted successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error Occurred ⚠️",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"md"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTrash} mr={2} />
          Confirm Delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Text textAlign={"center"} fontSize={"xl"}>
              Warning this action is{" "}
              <chakra.span color={"red.500"}>irreversible</chakra.span> and will
              end the current user's subscription.
              <br />
              <br />
              Are you sure you want to continue?
            </Text>
          </Stack>
          <Stack direction={"row"} mb={5} mt={8}>
            <Button
              variant={"delete"}
              isFullWidth
              onClick={async () => await deleteUser(uid)}
            >
              Delete
            </Button>
            <Button onClick={onClose} variant="outline" isFullWidth>
              Return
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function SearchBar(props: any) {
  return (
    <Formik
      initialValues={{ search: "", field: "" }}
      onSubmit={async (values: any, actions: any) => {
        props.setSearch({ field: values.field, result: values.search });
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack
            spacing={1}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Field name="field">
                {({ field, form }: any) => (
                  <FormControl id="field">
                    <Select
                      id="field"
                      name="field"
                      {...field}
                      onChange={(e: any) => {
                        form.setFieldValue("field", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Search by
                      </option>
                      <option value="first_name_lower">First name</option>
                      <option value="email">Email</option>
                      <option value="accounts.telegram.username">
                        Telegram
                      </option>
                      <option value="accounts.discord.username">Discord</option>
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Stack>
            <Stack direction={"row"}>
              <Field name="search">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.search && form.touched.search}
                  >
                    <Input
                      {...field}
                      id="search"
                      placeholder="Search"
                      w={{ base: "full", md: "25vw" }}
                    />
                  </FormControl>
                )}
              </Field>
              <Button
                isLoading={isSubmitting}
                type="submit"
                variant={"outline"}
              >
                <Icon as={FaSearch} />
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function FilterUI(props: any) {
  const buttons = [
    {
      filter: "first_name",
      name: "Name",
    },
    {
      filter: "email",
      name: "Email",
    },
    {
      filter: "joined",
      name: "Joined",
    },
    {
      filter: "expiration",
      name: "Expiration ",
    },
    {
      filter: "subscription",
      name: "Subscription",
    },
  ];

  return (
    <Flex ml={{ base: 0, md: 2 }} flexDir={"row"} flexWrap={"wrap"}>
      <Divider my={4} display={{ base: "block", md: "none" }} />
      {buttons.map((item, index) => (
        <Button
          my={{ base: 1, md: 0 }}
          key={index}
          variant={"outline"}
          mx={1}
          onClick={() => {
            props.setSearch(null);
            props.setDirection(
              item.filter !== props.order
                ? "asc"
                : props.direction === "desc"
                ? "asc"
                : "desc"
            );
            props.setOrder(item.filter);
          }}
        >
          {item.name}
          {props.order === item.filter && props.direction === "asc" && (
            <Icon as={FaArrowUp} ml={1} />
          )}
          {props.order === item.filter && props.direction === "desc" && (
            <Icon as={FaArrowDown} ml={1} />
          )}
        </Button>
      ))}
    </Flex>
  );
}
