import * as React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  useBreakpointValue,
  Img,
  Center,
  Heading,
  chakra,
} from "@chakra-ui/react";

import { useAuth } from "../auth/useAuth";

import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { FaSignOutAlt, FaUserAlt, FaUserSecret } from "react-icons/fa";

export default function NavBar() {
  const { user, signOut, isAdmin } = useAuth();

  return (
    <Box pos="fixed" w="100%" zIndex={100} top="0">
      <Flex
        bg={useColorModeValue("white", "blue.900")}
        color={useColorModeValue("gray.800", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        boxShadow="xl"
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <chakra.div
            as={Link}
            to={
              isAdmin && window.location.href.indexOf("admin") !== -1
                ? "/admin"
                : "/"
            }
          >
            <Flex data-tour="first-step">
              <Img boxSize="36px" objectFit="cover" src="/logo512.png" />
              <Center>
                <Heading
                  size={"md"}
                  textAlign={useBreakpointValue({ base: "center", md: "left" })}
                  color={"orange.900"}
                >
                  CryptoKnights
                </Heading>
              </Center>
            </Flex>
          </chakra.div>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={{ base: 2, md: 6 }}
        >
          <ColorModeSwitcher justifySelf="flex-end" />

          {user &&
            isAdmin &&
            (isAdmin && window.location.href.indexOf("admin") === -1 ? (
              <Button variant="outline" to={"/admin"} as={Link}>
                <Icon as={FaUserSecret} />
              </Button>
            ) : (
              <Button variant="outline" to={"/"} as={Link}>
                <Icon as={FaUserAlt} />
              </Button>
            ))}
          {user ? (
            <React.Fragment>
              <Button
                onClick={() => signOut()}
                fontSize={"sm"}
                fontWeight={600}
                variant="outline"
              >
                <Text display={{ base: "none", md: "inline-flex" }}>
                  Sign Out
                </Text>
                <Icon
                  display={{ base: "inline-flex", md: "none" }}
                  as={FaSignOutAlt}
                />
              </Button>
            </React.Fragment>
          ) : (
            <Button
              as={Link}
              fontSize={"sm"}
              fontWeight={600}
              to={"/signin"}
              variant="outline"
            >
              Sign In
            </Button>
          )}
        </Stack>
      </Flex>
    </Box>
  );
}
