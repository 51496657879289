import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Icon,
  useColorModeValue,
  Button,
  Heading,
  chakra,
} from "@chakra-ui/react";
import { Link } from "react-scroll";
import { FaChevronDown } from "react-icons/fa";

import logo from "../../assets/images/logo.png";

export default function Welcome() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return (
    <Flex
      bg={useColorModeValue("gray.50", "blue.900")}
      p={5}
      minH="96vh"
      justify={"center"}
      align={"center"}
      grow={1}
      direction={"column"}
      textAlign={"center"}
    >
      <Image
        src={logo}
        mt={{ base: 20, md: 16, xl: 20 }}
        width={{ base: "15rem", md: "30rem" }}
      />
      <Heading mt={"10"} fontSize={{ base: "2xl", md: "3xl" }}>
        Welcome to{" "}
        <chakra.span color={"orange.900"} className={"gagalin"}>
          CryptoKnights
        </chakra.span>
      </Heading>
      <Text fontSize={{ base: "2xl", md: "3xl" }} mt={5} variant={"Gagalin"}>
        Where we <chakra.span color={"orange.900"}>protect</chakra.span> your
        hard-earned money!
      </Text>
      <Button
        cursor={"pointer"}
        variant="outline"
        mt={8}
        size={"lg"}
        as={Link}
        to="service"
        spy={true}
        smooth={true}
      >
        Learn More
      </Button>
      {offset === 0 ? (
        <chakra.div
          mt={"auto"}
          cursor={"pointer"}
          as={Link}
          to="service"
          spy={true}
          smooth={true}
          _hover={{
            color: "orange.900",
          }}
        >
          <Icon w={10} h={10} as={FaChevronDown} />
        </chakra.div>
      ) : (
        <Box mt={"auto"} w={10} h={10} />
      )}
    </Flex>
  );
}
