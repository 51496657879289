import React from "react";
import { Flex, useColorModeValue, Grid } from "@chakra-ui/react";
import {
  MdQuestionAnswer,
  MdBook,
  MdShowChart,
  MdPeople,
  MdLink,
  MdWarning,
} from "react-icons/md";
import { PageHeader, DashCard } from "../../components/helpers/Helpers";

import "../../components/dashboard/dashboard.css";

export default function Dashboard(props: any) {
  return (
    <Flex
      minH="96vh"
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
      flexDir={"column"}
    >
      <PageHeader main={"Admin"} title={"Tools"} mt={2} />

      <Grid
        padding={{ base: 4, md: 6 }}
        templateColumns={{
          base: "repeat(2,  1fr)",
          lg: "repeat(3, 1fr)",
        }}
        templateRows={{
          base: "repeat(2,  minmax(170px, 1fr))",
          lg: "repeat(2, minmax(220px, 1fr))",
        }}
        alignItems={"center"}
        gap={{ base: 15, md: 20 }}
        w={"full"}
        justifyContent={"center"}
      >
        <DashCard
          title={"Statistics"}
          icon={MdShowChart}
          page={"admin/statistics"}
        />
        <DashCard title={"Users"} icon={MdPeople} page={"admin/users"} />
        <DashCard title={"Invites"} icon={MdLink} page={"admin/invites"} />
        <DashCard title={"FAQ"} icon={MdQuestionAnswer} page={"admin/FAQ"} />
        <DashCard title={"Resources"} icon={MdBook} page={"admin/resources"} />
        <DashCard title={"TEST"} icon={MdWarning} page={"admin/test"} />
      </Grid>
    </Flex>
  );
}
