import React from "react";
import {
  Heading,
  Box,
  Image,
  chakra,
  Text,
  HStack,
  Flex,
  Icon,
} from "@chakra-ui/react";

import DataTable from "../../components/admin/statistics/table";

import { Column } from "react-table";

import firebase from "../../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { FaExclamationTriangle } from "react-icons/fa";
import { ErrorCard, LoadingCard } from "../helpers/Helpers";

export function GetStats(props: any) {
  const userRef = firebase
    .firestore()
    .collection("statistics")
    .doc(props.month);
  const [value, loading, error] = useDocumentData(userRef);

  return value ? (
    <>
      <DataTable
        columns={
          props.extraColumnHeaders
            ? [...columnHeaders, ...props.extraColumnHeaders]
            : columnHeaders
        }
        data={value.results}
      />
      <Total data={value.results} />
    </>
  ) : loading ? (
    <LoadingCard />
  ) : error ? (
    <ErrorCard />
  ) : (
    <Flex flexDir={"column"} align={"center"}>
      <Icon
        as={FaExclamationTriangle}
        fontSize={"8rem"}
        my={4}
        color={"orange.900"}
      />
      <Text fontSize={"xl"} textAlign={"center"}>
        The selected month is currently empty
      </Text>
    </Flex>
  );
}

export type Stats = {
  added: string;
  coin: string;
  position: string;
  leverage: string;
  entry: number;
  closed: number;
  icon: string;
  profit: string;
};

export const columnHeaders: Column<Stats>[] = [
  {
    Header: "Coin",
    accessor: "coin",
    Cell: (row) => {
      const data = row.row.original;

      const icon = data.coin.split("/")[0].toLowerCase();

      return (
        <HStack>
          <Image
            name={icon}
            src={process.env.PUBLIC_URL + "/crypto/icon/" + icon + ".svg"}
            mr={"2"}
            w={7}
            h={7}
          />
          <Text>{data.coin}</Text>
        </HStack>
      );
    },
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Leverage",
    accessor: "leverage",
  },
  {
    Header: "Entry Price",
    accessor: "entry",
  },
  {
    Header: "Closed Price",
    accessor: "closed",
  },
  {
    Header: "Profit",
    Cell: (row: any) => {
      const data = row.row.original;
      const leverage =
        data.leverage === "none" ? 1 : data.leverage.replace("x", "");

      let profit: string;
      if (data.position === "SHORT" || data.position === "SHORT SCALP") {
        profit = ((data.closed / data.entry - 1) * -100 * leverage).toFixed(2);
      } else {
        profit = ((data.closed / data.entry - 1) * 100 * leverage).toFixed(2);
      }

      return (
        <chakra.span color={parseFloat(profit) > 0 ? "green.300" : "red.500"}>
          {profit + "%"}
        </chakra.span>
      );
    },
    accessor: "profit",
  },
];

export function Total(props: any) {
  var totalProfits: any = 0;

  props.data.forEach((val: any) => {
    const leverage =
      val.leverage === "none" ? 1 : val.leverage.replace("x", "");

    if (val.position === "SHORT" || val.position === "SHORT SCALP") {
      totalProfits += (val.closed / val.entry - 1) * -100 * leverage;
    } else {
      totalProfits += (val.closed / val.entry - 1) * 100 * leverage;
    }
  });

  totalProfits = totalProfits.toFixed(2);

  return (
    <Box w={"full"} mt={10} textAlign={"center"}>
      <Heading>
        Total:
        <chakra.span color={totalProfits >= 0 ? "green.300" : "red.22"} ml={2}>
          {totalProfits}%
        </chakra.span>
      </Heading>
    </Box>
  );
}

export function GetLastMonthSelected() {
  const d = new Date();
  const currentYear = d.getFullYear();
  const lastMonth = d.getMonth() - 1;

  const monthNumber = lastMonth < 0 ? 11 : lastMonth;
  const yearNumber = lastMonth < 0 ? currentYear - 1 : currentYear;

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber].toLowerCase() + yearNumber;
}
