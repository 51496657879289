import { Flex, Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import https from "https";

const chains = {
  ethereum: {
    URL: "api.etherscan.io",
    API: "9BZFPIYJYA5Z61311R83G1XIB3VBHGVTEF",
    RPC: "https://mainnet.infura.io/v3/52b4a388c711407db2842b0b44a45cf4",
  },
  ropsten: {
    URL: "api-ropsten.etherscan.io",
    API: "9BZFPIYJYA5Z61311R83G1XIB3VBHGVTEF",
    RPC: "https://ropsten.infura.io/v3/52b4a388c711407db2842b0b44a45cf4",
  },
  binance: {
    URL: "api.bscscan.com",
    API: "QVN9NZM4G3AX3NH63QFGV775TRID1NTSZ6",
    RPC: "https://bsc-dataseed.binance.org",
  },
};

export default function Test() {
  return (
    <Flex
      minH="96vh"
      bg={useColorModeValue("gray.50", "blue.900")}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={async () => {
        var status = 0;
        await getTransaction(
          "0x20fff04502200a622379c1f9d6e15eadc7d0f13a7ac299324cb72b85c42cd159",
          "binance",
          (err, data) => {
            if (!err) {
              status = 1;
              console.log("e");
              return;
            } else if (err === "FAILED") return;
          }
        ).then(async (reff) => {
          console.log(reff);
          console.log(status);
        });
      }}
    >
      <Button>DONT!</Button>
    </Flex>
  );
}

async function getTransaction(hash, chain, callback) {
  var options = {
    method: "GET",
    hostname: chains[chain.toLowerCase()].URL,
    path:
      "/api?module=transaction&action=gettxreceiptstatus&txhash=" +
      hash +
      "&apikey=" +
      chains[chain.toLowerCase()].API,
    headers: {},
  };
  var req = await https.request(options, function (res) {
    res.setEncoding("utf8");
    res.on("data", function (chunk) {
      const data = JSON.parse(chunk);
      if (data.status === "1" && data.result.status === "1") {
        return callback(null, 1);
      } else if (data.status === "1" && data.result.status === "0") {
        return callback("FAILED", data);
      } else {
        return callback(true, data);
      }
    });
  });
  req.on("error", function (e) {
    return callback(true, e);
  });
  req.end();
}
