import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast,
  FormLabel,
  Textarea,
  Select,
  Divider,
  Icon,
  Image,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { PageHeader } from "../components/helpers/Helpers";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useHistory } from "react-router-dom";
import { FaList, FaUser } from "react-icons/fa";
import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import Portfolios from "../components/portfolio/items";

export default function Support(props: any) {
  const { user } = useAuth();
  const [selectedPortfolio, setSelectedPortfolio] = useState("all");
  const data = [
    { name: "test", date: new Date(1630184528640), price: 1120.111198805953 },
    { name: "test", date: new Date(1630180929521), price: 1139.5726928552353 },
    { name: "test", date: new Date(1630188129890), price: 1124.0119238949237 },
    { name: "test", date: new Date(1630191729246), price: 1112.315983036978 },
    { name: "test", date: new Date(1630195331915), price: 1112.3194258646718 },
    { name: "test", date: new Date(1630198927837), price: 1112.3526442470893 },
    { name: "test", date: new Date(1630202531812), price: 1100.6745096755326 },
    { name: "test", date: new Date(1630206130199), price: 1092.867124450365 },
    { name: "test", date: new Date(1630209730736), price: 1069.4762499256333 },
    { name: "test", date: new Date(1630213329919), price: 1042.2178653869316 },
    { name: "test", date: new Date(1630216928899), price: 1022.7577857422272 },
    { name: "test", date: new Date(1630220526020), price: 1038.3409107322864 },
    { name: "test", date: new Date(1630224127387), price: 1050.0369733002726 },
    { name: "test", date: new Date(1630227733024), price: 1061.722531702119 },
    { name: "test", date: new Date(1630231331909), price: 1061.7216289014716 },
    { name: "test", date: new Date(1630234931294), price: 1100.658966884605 },
    { name: "test", date: new Date(1630238531960), price: 1073.3883199520228 },
    { name: "test", date: new Date(1630242136226), price: 1061.7060202266232 },
    { name: "test", date: new Date(1630245734047), price: 1034.4479947782802 },
    { name: "test", date: new Date(1630249333801), price: 1034.4310600535096 },
    { name: "test", date: new Date(1630252935182), price: 1030.5426763965386 },
    { name: "test", date: new Date(1630256533028), price: 1014.963192431596 },
    { name: "test", date: new Date(1630260136081), price: 1001.0288831915005 },
    { name: "test", date: new Date(1630263729854), price: 989.2903067479685 },
  ];

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: "bottom",
        type: "time",
        show: true,
      },
      { position: "left", type: "linear", show: false },
    ],
    []
  );

  const series = React.useMemo(
    () => ({
      showPoints: false,
    }),
    []
  );
  // const data = React.useMemo(
  //   () => [
  //     {
  //       data: [
  //         [new Date(1630180929521), 1139.5726928552353],
  //         [new Date(1630184528640), 1120.111198805953],
  //         [new Date(1630188129890), 1124.0119238949237],
  //         [new Date(1630191729246), 1112.315983036978],
  //         [new Date(1630195331915), 1112.3194258646718],
  //         [new Date(1630198927837), 1112.3526442470893],
  //         [new Date(1630202531812), 1100.6745096755326],
  //         [new Date(1630206130199), 1092.867124450365],
  //         [new Date(1630209730736), 1069.4762499256333],
  //         [new Date(1630213329919), 1042.2178653869316],
  //         [new Date(1630216928899), 1022.7577857422272],
  //         [new Date(1630220526020), 1038.3409107322864],
  //         [new Date(1630224127387), 1050.0369733002726],
  //         [new Date(1630227733024), 1061.722531702119],
  //         [new Date(1630231331909), 1061.7216289014716],
  //         [new Date(1630234931294), 1100.658966884605],
  //         [new Date(1630238531960), 1073.3883199520228],
  //         [new Date(1630242136226), 1061.7060202266232],
  //         [new Date(1630245734047), 1034.4479947782802],
  //         [new Date(1630249333801), 1034.4310600535096],
  //         [new Date(1630252935182), 1030.5426763965386],
  //         [new Date(1630256533028), 1014.963192431596],
  //         [new Date(1630260136081), 1001.0288831915005],
  //         [new Date(1630263729854), 989.2903067479685],
  //       ],
  //     },
  //   ],
  //   []
  // );

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Portfolio"} />
        <Flex flexWrap={"wrap"} mt={10}>
          <Flex
            flexDir={"column"}
            flex={1}
            bg={useColorModeValue("white", "gray.800")}
            rounded={"lg"}
            boxShadow={"lg"}
            p={6}
            mr={3}
            fontFamily={"Gagalin"}
          >
            <Button variant={"outline"}>Add Portfolio</Button>
            <Divider my={5} />
            {Object.keys(Portfolios).map((key, index) => {
              return (
                <PortfoliosItems
                  key={index}
                  name={Portfolios[key].name}
                  value={Portfolios[key].value}
                  image={Portfolios[key].image}
                  selected={selectedPortfolio}
                  setSelected={setSelectedPortfolio}
                />
              );
            })}
          </Flex>

          <Flex
            flexDir={"column"}
            flex={4}
            bg={useColorModeValue("white", "gray.800")}
            rounded={"lg"}
            boxShadow={"lg"}
            p={6}
            ml={3}
            fontFamily={"Gagalin"}
          >
            <Flex>
              <Flex flexDir={"column"} flex={1}>
                <Text fontSize={"3xl"}>$989.33</Text>
                <Stack
                  fontSize={"lg"}
                  direction={"row"}
                  spacing={4}
                  align={"center"}
                  display={"flex"}
                >
                  <Text color={"red.500"}>-$123.41</Text>
                  <Text color={"red.500"}>-15%</Text>
                  <Select size={"sm"} variant="flushed">
                    <option>24h</option>
                  </Select>
                </Stack>
              </Flex>
              <Flex h={""} flex={3} ml={20}>
                <ResponsiveContainer>
                  <LineChart data={data}>
                    <Line
                      type="monotone"
                      dataKey="price"
                      stroke="#f5b002"
                      dot={false}
                      strokeWidth={2}
                    />
                    <XAxis dataKey="date" />
                    <YAxis dataKey="price" />

                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

const PortfoliosItems = ({ name, image, setSelected, selected, value }) => {
  return (
    <Stack
      direction="row"
      h={10}
      align={"center"}
      color={selected === value ? "orange.900" : ""}
      mb={4}
      _hover={{ color: "orange.900", cursor: "pointer" }}
      onClick={() => setSelected(value)}
    >
      <Divider
        orientation="vertical"
        borderLeftWidth={selected === value ? "5px" : "1px"}
        borderColor={selected === value ? "orange.900" : "inherit"}
      />
      {image.type === "icon" ? (
        <Icon as={image.url} />
      ) : (
        <Image
          name={name}
          src={process.env.PUBLIC_URL + "/portfolio/icons/" + image.url}
          w={5}
          h={5}
        />
      )}

      <Text fontSize={"xl"}>{name}</Text>
    </Stack>
  );
};
