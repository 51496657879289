import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Textarea,
  Select,
} from "@chakra-ui/react";
import {
  ErrorCard,
  LoadingCard,
  PageHeader,
} from "../../components/helpers/Helpers";

import firebase from "../../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import DataTable from "../../components/admin/statistics/table";

import { FaPen, FaTrash } from "react-icons/fa";

import { Formik, Form, Field } from "formik";
import parse from "html-react-parser";

export default function AdminFAQ(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentFAQ, setCurrentFAQ] = useState("");
  const toast = useToast();

  const FAQRef = firebase.firestore().collection("content").doc("FAQ");

  const deleteFAQ = async (doc) => {
    await FAQRef.set(
      {
        items: firebase.firestore.FieldValue.arrayRemove(doc),
      },
      { merge: true }
    )
      .then(() => {
        toast({
          title: "Deleted Successfully 👍",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) =>
        toast({
          title: "Error Occurred 😭",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      );
  };

  const [value, loading, error] = useDocumentData(FAQRef);

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"FAQs"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"md"}
          p={6}
          mt={10}
        >
          <Flex align={"center"} w={"full"} justify={"center"}>
            <NewFAQ />
          </Flex>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {value && value.items && value.items.length > 0 && (
            <DataTable
              columns={columnHeaders(setCurrentFAQ, onOpen, deleteFAQ)}
              data={value.items}
            />
          )}
          {value && value.items === 0 && (
            <Text textAlign={"center"}>There are currently no FAQs</Text>
          )}

          {error && <ErrorCard />}
          {loading && <LoadingCard />}

          {currentFAQ && (
            <EditFAQModal faq={currentFAQ} onClose={onClose} isOpen={isOpen} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const columnHeaders = (setCurrentFAQ: any, onOpen: any, deleteFAQ: any) => [
  {
    Header: "#",
    accessor: "order",
    Cell: (row) => {
      return <Text>{row.row.index + 1}</Text>;
    },
  },
  {
    Header: "Question",
    accessor: "question",
    Cell: (row) => {
      const data = row.row.original;
      return <Text>{parse(truncate(data.question, 50))}</Text>;
    },
  },
  {
    Header: "Answer",
    accessor: "answer",
    Cell: (row) => {
      const data = row.row.original;
      return <Text>{parse(truncate(data.answer, 75))}</Text>;
    },
  },
  {
    Header: "Actions",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Flex>
          <IconButton
            icon={<FaPen />}
            aria-label="Edit"
            mr={3}
            variant={"outline"}
            onClick={() => {
              setCurrentFAQ({
                ...data,
                index: row.row.index,
                total: row.data.length,
              });
              onOpen();
            }}
          />
          <IconButton
            icon={<FaTrash />}
            aria-label="Delete"
            colorScheme={"red"}
            background={"red.500"}
            onClick={() => deleteFAQ(data)}
          />
        </Flex>
      );
    },
  },
];

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

function EditFAQModal({ faq, onClose, isOpen }: any) {
  let allOrder: any = [];
  for (var i = 0; i < faq.total; i++) {
    allOrder.push(<option key={i + 1}>{i + 1}</option>);
  }

  const toast = useToast();
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"3xl"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>Edit FAQ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{ ...faq, order: faq.index + 1 }}
            onSubmit={async (values: any, actions: any) => {
              await firebase
                .firestore()
                .collection("content")
                .doc("FAQ")
                .get()
                .then(async (doc: any) => {
                  if (doc && doc.exists) {
                    let items = doc.data().items;
                    if (values.order - 1 !== faq.index) {
                      items.splice(faq.index, 1);

                      items.splice(values.order - 1, 0, {
                        question: values.question,
                        answer: values.answer,
                      });
                    } else {
                      items[faq.index] = {
                        question: values.question,
                        answer: values.answer,
                      };
                    }

                    await doc.ref
                      .set({
                        items: items,
                      })
                      .then(() => {
                        toast({
                          title: "Updated Successfully 👍",
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });
                      });
                    onClose();
                  }
                })
                .catch((err) => {
                  toast({
                    title: "Error Occurred ⚠️",
                    description: err.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                });
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="question">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="question">Question</FormLabel>
                        <Input {...field} id="question" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="answer">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="answer">Answer</FormLabel>
                        <Textarea {...field} id="answer" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="order">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="order">Order</FormLabel>
                        <Select {...field} id="order">
                          {allOrder}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  isFullWidth
                  variant="outline"
                  isLoading={isSubmitting}
                  type="submit"
                  mt={10}
                  mb={6}
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const NewFAQ = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const FAQRef = firebase.firestore().collection("content").doc("FAQ");

  return (
    <>
      <Button onClick={onOpen} variant={"outline"} mx={1}>
        Create FAQ
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={"md"}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
          <ModalHeader>Create FAQ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                question: "",
                answer: "",
              }}
              onSubmit={async (values: any, actions: any) => {
                await FAQRef.set(
                  {
                    items: firebase.firestore.FieldValue.arrayUnion({
                      question: values.question,
                      answer: values.answer,
                    }),
                  },
                  { merge: true }
                )
                  .then(() => {
                    toast({
                      title: "FAQ created successfully 👍",
                      status: "success",
                      duration: 8000,
                      isClosable: true,
                    });
                    onClose();
                  })
                  .catch((err) => {
                    toast({
                      title: "Error Occurred 😭",
                      description: err.message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  });

                actions.setSubmitting(false);
              }}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <Stack spacing={4}>
                    <Field name="question">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="question">Question</FormLabel>
                          <Input {...field} id="question" />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="answer">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="answer">Answer</FormLabel>
                          <Textarea {...field} id="answer" />
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Button
                    isFullWidth
                    variant="outline"
                    isLoading={isSubmitting}
                    type="submit"
                    mt={10}
                    mb={6}
                  >
                    Create
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
