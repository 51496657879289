import * as React from "react";
import { Global } from "@emotion/react";

import font from "./Gagalin/OTF/Gagalin-Regular.otf";

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Gagalin';
        src: local('Gagalin'), url(${font}) format('opentype');
      }

      .gagalin{
        font-family: Gagalin;
      }
      html, body {
          max-width: 100%;
          overflow-x: hidden;
      }
      /* width */
      ::-webkit-scrollbar {
        width: 10px;
      }

      @media screen and (max-width: 48em) {
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #0d0c1d; 
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #f5b002; 
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #f59802; 
      }
      `}
  />
);
