import React from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Input,
  useToast,
  FormHelperText,
  HStack,
  useClipboard,
  Icon,
} from "@chakra-ui/react";
import {
  ErrorCard,
  LoadingCard,
  PageHeader,
} from "../../components/helpers/Helpers";

import firebase from "../../services/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import DataTable from "../../components/admin/statistics/table";

import {
  FaCheckCircle,
  FaClipboard,
  FaTrash,
  FaInfinity,
} from "react-icons/fa";
import TimeAgo from "react-timeago";

import { Formik, Form, Field } from "formik";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/admin/users/date-picker.css";

export default function AdminUsers(props: any) {
  const toast = useToast();

  const inviteRef = firebase
    .firestore()
    .collection("invite_links")
    .orderBy("creation", "desc");

  const deleteInvite = async (id) => {
    const docRef = firebase.firestore().collection("invite_links").doc(id);

    await docRef
      .delete()
      .then(() => {
        toast({
          title: "Deleted Successfully 👍",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error Occurred 😭",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const [values, loading, error] = useCollectionData(inviteRef, {
    idField: "uid",
  });

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Invites"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"md"}
          p={6}
          mt={10}
        >
          <Flex align={"center"} w={"full"} justify={"center"}>
            <NewInviteLink />
          </Flex>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {values && values.length > 0 && (
            <DataTable columns={columnHeaders(deleteInvite)} data={values} />
          )}
          {values && values.length === 0 && (
            <Text textAlign={"center"}>
              There are currently no invite links
            </Text>
          )}
          {error && <ErrorCard />}
          {loading && <LoadingCard />}
        </Box>
      </Flex>
    </Flex>
  );
}

const columnHeaders = (deleteInvite: any) => [
  {
    Header: "Name",
    accessor: "customName",
    Cell: (row) => {
      const data = row.row.original;
      return (
        <Text>
          {!data.customName
            ? "-"
            : data.customName.replace(/(.{25})..+/, "$1&hellip;")}
        </Text>
      );
    },
  },
  {
    Header: "Usage",
    accessor: "usage",
    Cell: (row) => {
      const data = row.row.original;
      return <Text>{data.used + "/" + data.amount}</Text>;
    },
  },
  {
    Header: "Expiration Date",
    accessor: "expiration",
    Cell: (row) => {
      const data = row.row.original;
      if (data.expiration === "unlimited")
        return <Icon color={"orange.900"} as={FaInfinity} />;
      else return <TimeAgo date={new Date(data.expiration.seconds * 1000)} />;
    },
  },
  {
    Header: "Created",
    accessor: "creation",
    Cell: (row) => {
      const data = row.row.original;
      return data.creation?.seconds ? (
        <TimeAgo date={new Date(data.creation.seconds * 1000)} />
      ) : (
        <Text>-</Text>
      );
    },
  },
  {
    Header: "Actions",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Flex>
          <CopyLink id={data.uid} />
          <IconButton
            icon={<FaTrash />}
            aria-label="Delete"
            colorScheme={"red"}
            background={"red.500"}
            onClick={() => deleteInvite(data.uid)}
          />
        </Flex>
      );
    },
  },
];

function CopyLink({ id }: any) {
  const { hasCopied, onCopy } = useClipboard(
    `https://thecryptoknights.io/invite/${id}`
  );

  return (
    <IconButton
      icon={hasCopied ? <FaCheckCircle /> : <FaClipboard />}
      aria-label="View"
      mr={3}
      variant={"outline"}
      onClick={() => {
        onCopy();
      }}
    />
  );
}

const NewInviteLink = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const inviteRef = firebase.firestore().collection("invite_links");

  function validateAmount(value) {
    let error;
    if (!value) {
      error = "Amount is incorrect";
    }
    return error;
  }

  return (
    <>
      <Button onClick={onOpen} variant={"outline"} mx={1}>
        Create Invite Link
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={"md"}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
          <ModalHeader>Create Invite Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                amount: 1,
                term: "1",
                customName: "",
                expiration: new Date(),
              }}
              onSubmit={async (values: any, actions: any) => {
                const exp =
                  values.term === "1"
                    ? "unlimited"
                    : firebase.firestore.Timestamp.fromDate(values.expiration);
                await inviteRef
                  .add({
                    amount: parseInt(values.amount),
                    expiration: exp,
                    creation: firebase.firestore.FieldValue.serverTimestamp(),
                    customName: values.customName,
                    used: 0,
                  })
                  .then((doc) => {
                    toast({
                      title: "Invite link created successfully 👍",
                      description:
                        "https://thecryptoknights.io/invite/" + doc.id,
                      status: "success",
                      duration: 8000,
                      isClosable: true,
                    });
                    onClose();
                  })
                  .catch((err) => {
                    toast({
                      title: "Error Occurred 😭",
                      description: err.message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  });

                actions.setSubmitting(false);
              }}
            >
              {({ errors, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <Field name="customName">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="customName">
                            Custom Name
                          </FormLabel>
                          <Input {...field} id="customName" />
                          <FormHelperText>Only visible to you</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="term">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="term">Term</FormLabel>
                          <RadioGroup {...field} id="term">
                            <HStack spacing="24px" align={"center"}>
                              <Radio {...field} value={"0"}>
                                Predefined
                              </Radio>
                              <Radio {...field} value={"1"}>
                                Unlimited
                              </Radio>
                            </HStack>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Field>
                    {values.term === "0" && (
                      <Field name="expiration">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="expiration">
                              Subscription Expiration Date
                            </FormLabel>
                            <DatePicker
                              minDate={new Date()}
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              onChange={(d) => {
                                setFieldValue("expiration", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                    )}

                    <Field name="amount" validate={validateAmount}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.amount && form.touched.amount}
                        >
                          <FormLabel htmlFor="amount">Amount</FormLabel>
                          <Input {...field} id="amount" type="number" />
                          <FormHelperText>
                            Amount of time the link could be used
                          </FormHelperText>
                          <FormErrorMessage>
                            {form.errors.amount}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <HStack mt={20} mb={6}>
                    <Button
                      isFullWidth
                      variant="outline"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Create
                    </Button>
                    <Button isFullWidth onClick={onClose} variant="outline">
                      Return
                    </Button>
                  </HStack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
