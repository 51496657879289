import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Icon,
  useColorMode,
  Text,
  Heading,
  chakra,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaAddressCard, FaUserCircle, FaWallet } from "react-icons/fa";
import {
  PageHeader,
  SectionHeader,
  ErrorCard,
  LoadingCard,
} from "../components/helpers/Helpers";

import Tour from "reactour";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import InformationView from "../components/profile/information";
import { AccountView } from "../components/profile/accounts";
import PaymentsView from "../components/profile/payments";

export default function Profile(props: any) {
  const { user } = useAuth();
  const [tab, setTab] = useState("Information");
  const { colorMode } = useColorMode();
  const userRef = firebase.firestore().collection("users").doc(user.uid);

  const [value, loading, error] = useDocumentData(userRef);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [
    {
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading mb={4}>
            Welcome to{" "}
            <chakra.span color={"orange.900"}>CryptoKnights</chakra.span>
          </Heading>
          <Text fontSize={"lg"}>
            Follow the steps below for a simple tutorial
          </Text>
        </Flex>
      ),
      position: "center",
    },
    {
      selector: '[data-tour="first-step"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
        "margin-top": "20px",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Dashboard
          </Heading>
          <Text fontSize={"lg"}>
            Clicking on{" "}
            <Heading fontSize={"lg"} color={"orange.900"} display={"inline"}>
              CryptoKnights
            </Heading>{" "}
            will allow you to navigate to the dashboard no matter where you're
            located on the website
          </Text>
        </Flex>
      ),
      position: "right",
    },
    {
      selector: '[data-tour="second-step"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Profile
          </Heading>
          <Text fontSize={"lg"}>
            Complete your profile details for a better tailored experience
          </Text>
        </Flex>
      ),
      position: "top",
    },
    {
      selector: '[data-tour="third-step"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Accounts
          </Heading>
          <Text fontSize={"lg"}>View your Telegram and Discord accounts</Text>
        </Flex>
      ),
      position: "top",
    },
    {
      selector: '[data-tour="fourth-step"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Payments
          </Heading>
          <Text fontSize={"lg"}>
            Preview all your completed and pending payments
          </Text>
        </Flex>
      ),
      position: "top",
    },
    {
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        "max-width": useBreakpointValue({ base: "-300px", md: "500px" }),
        "text-align": "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            🥳You're all set🥳
          </Heading>
          <Text fontSize={"lg"}>
            If you have any questions or concerned do not hesitate to contact us
            through the Support tab found in the Dashboard.
            <br />
            <br />
            We hope you will enjoy your experience with{" "}
            <Heading color={"orange.900"} fontSize={"lg"}>
              CryptoKnights
            </Heading>
          </Text>
          <Button
            variant={"outline"}
            mt={6}
            onClick={() => setIsTourOpen(false)}
          >
            Exit
          </Button>
        </Flex>
      ),
      position: "center",
    },
  ];

  let query = new URLSearchParams(useLocation().search);
  let redirectTab: any = useLocation().state;

  useEffect(() => {
    if (query.get("code")) {
      setTab("Accounts");
    }
    if (redirectTab?.tab === "accounts") {
      setTab("Accounts");
    }
    if (props.location.state?.signUp) {
      setIsTourOpen(true);
    }
  }, []);

  const colors = [useColorModeValue("white", "gray.800")];

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor={"#f5b002"}
        disableInteraction={true}
        closeWithMask={false}
        badgeContent={(curr, tot) => (
          <Text fontSize={"xl"} variant={"Gagalin"} lineHeight={1}>
            {curr}
          </Text>
        )}
        lastStepNextButton={<></>}
      />
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Profile"} />
        {props.location.state?.signUp && (
          <Box
            w={"full"}
            bg={colors[0]}
            rounded={"lg"}
            boxShadow={"lg"}
            p={6}
            mt={10}
            textAlign={"center"}
          >
            <Text fontSize={"lg"}>
              Welcome to{" "}
              <Heading color={"orange.900"} fontSize={"xl"} display={"inline"}>
                CryptoKnights
              </Heading>
              ! Please complete your profile below
            </Text>
          </Box>
        )}
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          <Flex my={{ base: 0, md: 6 }} flexDir={{ base: "column", md: "row" }}>
            <Button
              width={"full"}
              variant="outline"
              size={"lg"}
              mx={{ base: 0, md: 2 }}
              my={{ base: 2, md: 0 }}
              bg={tab !== "Information" ? "" : "orange.900"}
              onClick={async () => {
                setTab("Information");
              }}
              color={
                colorMode === "dark"
                  ? "white"
                  : tab === "Information"
                  ? "white"
                  : "orange.900"
              }
            >
              <Icon as={FaAddressCard} mr={2} />
              Information
            </Button>
            <Button
              data-tour="third-step"
              width={"full"}
              variant="outline"
              size={"lg"}
              bg={tab !== "Accounts" ? "" : "orange.900"}
              onClick={async () => {
                setTab("Accounts");
              }}
              mx={{ base: 0, md: 2 }}
              my={{ base: 2, md: 0 }}
              color={
                colorMode === "dark"
                  ? "white"
                  : tab === "Accounts"
                  ? "white"
                  : "orange.900"
              }
            >
              <Icon as={FaUserCircle} mr={2} />
              Accounts
            </Button>
            <Button
              data-tour="fourth-step"
              width={"full"}
              variant="outline"
              size={"lg"}
              bg={tab !== "Payments" ? "" : "orange.900"}
              onClick={async () => {
                setTab("Payments");
              }}
              mx={{ base: 0, md: 2 }}
              my={{ base: 2, md: 0 }}
              color={
                colorMode === "dark"
                  ? "white"
                  : tab === "Payments"
                  ? "white"
                  : "orange.900"
              }
            >
              <Icon as={FaWallet} mr={2} />
              Payments
            </Button>
          </Flex>
          <Flex flexDir={"column"} data-tour="second-step">
            {tab === "Payments" ? (
              <SectionHeader
                title={tab}
                mb={{ base: 1, md: 5 }}
                expiredOn={value}
                direction={"column"}
              />
            ) : (
              <SectionHeader title={tab} mb={{ base: 1, md: 5 }} />
            )}

            {error && <ErrorCard />}
            {(loading || !value) && <LoadingCard />}

            {tab === "Information" && value && (
              <InformationView value={value} />
            )}
            {tab === "Accounts" && value && <AccountView value={value} />}
            {tab === "Payments" && <PaymentsView />}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
