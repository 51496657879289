import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  Icon,
  Image,
  List,
  ListItem,
  ListIcon,
  Modal,
  Link as LinkUI,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Tag,
  chakra,
  VStack,
  useColorMode,
  useToast,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaCheck, FaCheckCircle, FaCoins, FaCreditCard } from "react-icons/fa";
import TransactionModal from "../components/transaction/modal";
import { PageHeader, SectionHeader } from "../components/helpers/Helpers";
import Tour from "reactour";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  chains,
  IAppState,
  INITIAL_STATE,
  currencies,
  methods,
  periods,
  paymentAddress,
} from "../services/subscriptions";

import Web3 from "web3";
import Web3Modal from "web3modal";

import CoinbaseCommerceButton from "react-coinbase-commerce";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import { Link } from "react-scroll";
import parse from "html-react-parser";

export default function SubscriptionView() {
  const { user, isAdmin } = useAuth();
  const userRef = firebase.firestore().collection("users").doc(user.uid);
  const [value, loading] = useDocumentData(userRef, { refField: "ref" });

  const [period, setPeriod] = useState(12);
  const [method, setMethod] = useState("");
  const [currency, setCurrency] = useState("");
  const [total, setTotal]: any = useState(0);
  const { colorMode } = useColorMode();
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (value && !value.tutorials?.subscription) {
      setIsTourOpen(true);
      value.ref.update({ "tutorials.subscription": true }, { merge: true });
    }
  }, [loading]);

  const steps = [
    {
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: useBreakpointValue({ base: "-300px", md: "500px" }),
        textAlign: "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Subscriptions
          </Heading>
          <Text fontSize={"lg"}>
            This page allows you to subscribe for a subscription to get
            exclusive access to our premium chats and trading signals.
            <br />
            <br />
            Follow the steps below for a tutorial
          </Text>
        </Flex>
      ),
      position: "center",
    },
    {
      selector: '[data-tour="first-step-subscription"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: useBreakpointValue({ base: "-300px", md: "500px" }),
        textAlign: "center",
        mt: useBreakpointValue({ base: "-25vh", md: 0 }),
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Subscription Period
          </Heading>
          <Text fontSize={"lg"}>
            Select the desired{" "}
            <Heading color={"orange.900"} fontSize={"lg"} display={"inline"}>
              CryptoKnights
            </Heading>{" "}
            subscription period
          </Text>
        </Flex>
      ),
      position: "top",
    },
    {
      selector: '[data-tour="second-step-subscription"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: "300px",
        textAlign: "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Currency
          </Heading>
          <Text fontSize={"lg"}>Select your preferred crypto currency</Text>
        </Flex>
      ),
      position: "top",
    },
    {
      selector: '[data-tour="third-step-subscription"]',
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: useBreakpointValue({ base: "-300px", md: "500px" }),
        textAlign: "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Payment Method
          </Heading>
          <Text fontSize={"lg"}>
            Select to pay using the MetaMask's browser extension or via Coinbase
          </Text>
        </Flex>
      ),
      position: "top",
    },
    {
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: useBreakpointValue({ base: "-300px", md: "500px" }),
        textAlign: "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Total
          </Heading>
          <Text fontSize={"lg"}>
            The total of the payment will be displayed under the Payment Method
            section after selecting all three subscription options
          </Text>
        </Flex>
      ),
      position: "top",
    },
    {
      style: {
        backgroundColor: useColorModeValue(
          "var(--chakra-colors-white)",
          "var(--chakra-colors-gray-900)"
        ),
        padding: "50px",
        maxWidth: useBreakpointValue({ base: "-300px", md: "500px" }),
        textAlign: "center",
      },
      content: ({ goTo, inDOM }) => (
        <Flex flexDir={"column"}>
          <Heading color={"orange.900"} mb={4}>
            Need Help
          </Heading>
          <Text fontSize={"lg"}>
            If you have any questions or concerned do not hesitate to contact us
            through the Support tab found in the{" "}
            <Heading color={"orange.900"} fontSize={"lg"} display={"inline"}>
              CryptoKnights
            </Heading>{" "}
            Dashboard.
          </Text>
          <Button
            variant={"outline"}
            mt={6}
            onClick={() => setIsTourOpen(false)}
          >
            Exit
          </Button>
        </Flex>
      ),
      position: "center",
    },
  ];

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor={"#f5b002"}
        disableInteraction={true}
        closeWithMask={false}
        badgeContent={(curr, tot) => (
          <Text fontSize={"xl"} variant={"Gagalin"} lineHeight={1}>
            {curr}
          </Text>
        )}
        lastStepNextButton={<></>}
      />
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Subscriptions"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          <SectionHeader
            title={"- What's Included -"}
            divider={false}
            textAlign={"center"}
            right={
              <Text
                variant={"Gagalin"}
                color={"orange.900"}
                cursor={"pointer"}
                onClick={() => setIsTourOpen(true)}
              >
                How it works
              </Text>
            }
          />

          <List mt={4} textAlign={"center"} spacing={3} variant={"Gagalin"}>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 5, md: 10 }}
              justifyContent={"center"}
              px={2}
            >
              <ListItem fontSize={{ base: "md", md: "lg" }}>
                <ListIcon as={FaCheck} color="green.400" />
                Access our premium group chat
              </ListItem>
              <ListItem fontSize={{ base: "md", md: "lg" }}>
                <ListIcon as={FaCheck} color="green.400" />
                One-on-one support with our team
              </ListItem>
            </Stack>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 5, md: 10 }}
              w={"full"}
              justifyContent={"center"}
              px={2}
            >
              <ListItem fontSize={{ base: "md", md: "lg" }}>
                <ListIcon as={FaCheck} color="green.400" />
                Access amazing tools and bots
              </ListItem>
              <ListItem fontSize={{ base: "md", md: "lg" }}>
                <ListIcon as={FaCheck} color="green.400" />
                Access trading setups and charts 24/7
              </ListItem>
            </Stack>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 5, md: 10 }}
              w={"full"}
              justifyContent={"center"}
              px={2}
            >
              <ListItem fontSize={{ base: "md", md: "lg" }}>
                <ListIcon as={FaCheck} color="green.400" />
                Access to our hot trading tips and latest news in the world of
                crypto
              </ListItem>
            </Stack>
            <Text
              display={{ base: "block", md: "none" }}
              variant={"Gagalin"}
              color={"orange.900"}
              cursor={"pointer"}
              onClick={() => setIsTourOpen(true)}
            >
              How it works
            </Text>
          </List>
          <Box>
            <SectionHeader title={"Subscription Period"} />
            <Stack
              direction={{ base: "column", md: "row" }}
              data-tour="first-step-subscription"
            >
              {periods.map((res) => {
                if (!res.admin || (res.admin && isAdmin))
                  return (
                    <Button
                      key={res.name}
                      variant="outline"
                      size={"lg"}
                      bg={period !== res.value ? "" : "orange.900"}
                      _hover={{
                        bg: {
                          base: period !== res.value ? "" : "orange.900",
                          md: "orange.900",
                        },
                      }}
                      onClick={async () => {
                        setPeriod(res.value);
                        if (currency !== "")
                          setTotal(await getPrice(currency, res.value, method));
                      }}
                      color={
                        colorMode === "dark"
                          ? "white"
                          : period === res.value
                          ? "white"
                          : "orange.900"
                      }
                    >
                      {res.name}
                      <Tag ml={5}>${res.price}USD</Tag>
                    </Button>
                  );
                else return <></>;
              })}
            </Stack>
            <SectionHeader title={"Currency"} />
            <Stack
              direction={{ base: "column", md: "row" }}
              flexWrap={"wrap"}
              data-tour="second-step-subscription"
            >
              {currencies.map((res) => {
                return (
                  <Box key={res.symbol}>
                    <Button
                      isFullWidth
                      my={2}
                      variant="outline"
                      size={"lg"}
                      _hover={{
                        bg: {
                          base: currency !== res.symbol ? "" : "orange.900",
                          md: "orange.900",
                        },
                      }}
                      bg={currency !== res.symbol ? "" : "orange.900"}
                      opacity={
                        method !== "" &&
                        res.method !== method &&
                        res.method !== "both"
                          ? "0.4"
                          : "1"
                      }
                      onClick={async () => {
                        setCurrency(res.symbol);
                        if (res.method !== "both") {
                          setMethod(res.method);
                          setTotal(
                            await getPrice(res.symbol, period, res.method)
                          );
                        } else if (
                          method !== res.method &&
                          res.method !== "both"
                        ) {
                          setMethod("");
                          setTotal(0);
                        } else if (res.method !== "both" || method)
                          setTotal(await getPrice(res.symbol, period, method));
                      }}
                      color={
                        colorMode === "dark"
                          ? "white"
                          : currency === res.symbol
                          ? "white"
                          : "orange.900"
                      }
                      cursor={"pointer"}
                      as={Link}
                      to="method"
                      spy={true}
                      smooth={true}
                    >
                      <Image
                        src={"./crypto/icon/" + res.icon + ".svg"}
                        w={6}
                        mr={2}
                      />
                      {res.symbol}
                    </Button>
                  </Box>
                );
              })}
            </Stack>
            <SectionHeader title={"Payment Method"} id={"method"} />
            <Stack
              direction={{ base: "column", md: "row" }}
              data-tour="third-step-subscription"
            >
              {methods.map((res) => {
                const currencyMethod =
                  currencies[currencies.findIndex((x) => x.symbol === currency)]
                    ?.method;
                return (
                  <Button
                    key={res.name}
                    variant="outline"
                    size={"lg"}
                    _hover={{
                      bg: {
                        base: method !== res.name ? "" : "orange.900",
                        md: "orange.900",
                      },
                    }}
                    opacity={
                      currencyMethod !== res.name &&
                      currencyMethod !== "both" &&
                      method !== "" &&
                      method !== res.name
                        ? "0.4"
                        : "1"
                    }
                    bg={method !== res.name ? "" : "orange.900"}
                    onClick={async () => {
                      setMethod(res.name);
                      if (
                        (currency !== "" && currencyMethod === res.name) ||
                        currencyMethod === "both"
                      ) {
                        setTotal(await getPrice(currency, period, method));
                      } else {
                        setCurrency("");
                        setTotal(0);
                      }
                    }}
                    color={
                      colorMode === "dark"
                        ? "white"
                        : method === res.name
                        ? "white"
                        : "orange.900"
                    }
                  >
                    <Image src={res.icon} w={6} mr={2} />
                    {res.name}
                  </Button>
                );
              })}
            </Stack>
            <Flex id={"total"} direction={"column"}>
              {total > 0 && (
                <>
                  <SectionHeader title={"Total"} />
                  <Heading size={"lg"} align={"center"} mt={4}>
                    <Icon as={FaCoins} mr={2} fontSize={20} />
                    <chakra.span color={"orange.900"} mr={2}>
                      {total}
                    </chakra.span>
                    {currency}
                  </Heading>
                  {method === "coinbase" ? (
                    <PayCoinbase period={period} />
                  ) : (
                    <SelectChain
                      currency={currency}
                      total={total}
                      period={period}
                    />
                  )}
                </>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

function PayCoinbase({ period }: any) {
  const { user } = useAuth();
  const toast = useToast();

  return (
    <CoinbaseCommerceButton
      checkoutId={
        periods[periods.findIndex((x) => x.value === period)].coinbase
      }
      customMetadata={user.uid}
      style={{ width: "100%" }}
      onChargeFailure={(te: any) => {
        toast({
          title: "Payment Failed. Please contact us for support",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }}
      onChargeSuccess={() =>
        toast({
          title: "Payment Successful. View your profile for your subscription",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      }
    >
      <Button variant="outline" isFullWidth mt={10} size={"lg"}>
        <Icon as={FaCreditCard} mr={2} fontSize={20} />
        Pay & Subscribe
      </Button>
    </CoinbaseCommerceButton>
  );
}

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
}

function useStateRef(initialValue) {
  const [payment, setPayment] = useState(initialValue);

  const ref = useRef(payment);

  useEffect(() => {
    ref.current = payment;
  }, [payment]);

  return [payment, setPayment, ref];
}

function SelectChain({ currency, total, period }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = useState<IAppState>(INITIAL_STATE);
  const [chain, setChain] = useState("");
  const toast = useToast();
  const { user } = useAuth();

  const [payment, setPayment, ref] = useStateRef(false);

  var web3: any = null;

  async function openModal() {
    try {
      if (payment && payment.status === "pending") {
        onOpen();
      } else if (web3 === null) {
        if (
          payment &&
          (payment.status === "completed" || payment.status === "failed")
        ) {
          setPayment(false);
          setState(INITIAL_STATE);
        }
        const modal: any = new Web3Modal({
          cacheProvider: false,
        });
        if (
          !modal.providerController ||
          !modal.providerController.injectedProvider
        ) {
          throw new Error(
            "Please make sure you have MetaMask installed before proceeding. <a href='https://metamask.io/download' target='_blank'><b>Install here</b></a>"
          );
        }

        modal.clearCachedProvider();
        const provider = await modal.connect();

        web3 = await initWeb3(provider);

        const accounts = await web3.eth.getAccounts();

        const address = accounts[0];

        const networkId = await web3.eth.net.getId();

        const chainId = await web3.eth.chainId();

        Object.keys(chains).forEach((key) => {
          if (chains[key].id === chainId) setChain(key);
        });

        setState((previousState) => ({
          ...previousState,
          web3,
          provider,
          connected: true,
          address,
          chainId,
          networkId,
        }));
        if (provider.on) {
          provider.on("accountsChanged", async (accounts: string[]) => {
            setState((previousState) => ({
              ...previousState,
              address: accounts[0],
            }));
          });
          provider.on("chainChanged", async (chainId: number) => {
            const networkId = await web3.eth.net.getId();
            const chainID = await web3.eth.chainId();
            setState((previousState) => ({
              ...previousState,
              chainId: chainID,
              networkId: networkId,
            }));
          });
        }
        onOpen();
      }
    } catch (err) {
      toast({
        title: "Error",
        description: parse(err.message),
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  }

  async function sendPayment(currency: any, total: any, period: any) {
    const chainId = state.chainId;
    const chainSelected = chains[chain].id;
    if (chainId !== chainSelected) {
      toast({
        title: "Please change the network on MetaMask",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      var contractAddress = chains[chain].contracts[currency];
      var tenPower = 18;
      if (
        (currency === "USDT" || currency === "USDC") &&
        chain === "Ethereum"
      ) {
        tenPower = 6;
      }
      try {
        let minABI: any = [
          {
            constant: false,
            inputs: [
              {
                name: "_to",
                type: "address",
              },
              {
                name: "_value",
                type: "uint256",
              },
            ],
            name: "transfer",
            outputs: [
              {
                name: "",
                type: "bool",
              },
            ],
            type: "function",
          },
        ];
        var value;
        if (currency === "ETH" || currency === "BNB") {
          value = state.web3.utils.toWei(total.toString(), "ether");
        } else {
          // calculate ERC20 token amount
          let decimals = state.web3.utils.toBN(tenPower);
          let amount = state.web3.utils.toBN(parseFloat(total));
          value = amount.mul(state.web3.utils.toBN(10).pow(decimals));
        }

        const accounts = await state.web3.eth.getAccounts();
        const gasPrice = await state.web3.eth.getGasPrice();

        if (
          (currency === "ETH" && chain === "Ethereum") ||
          (currency === "BNB" && chain === "Binance") ||
          (currency === "ETH" && chain === "Ropsten")
        ) {
          state.web3.eth
            .sendTransaction({
              from: accounts[0],
              to: paymentAddress,
              value: value,
              gasPrice: gasPrice,
              gasLimit: "0x" + (300000).toString(16),
            })
            .once("transactionHash", function (hash) {
              setPayment((prev) => ({
                ...prev,
                status: "pending",
                confirmations: 0,
                currency: currency,
                hash: hash,
                total: total,
              }));
              firebase
                .firestore()
                .collection("transactions")
                .add({
                  uid: user.uid,
                  hash: hash,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  chain: chain,
                  currency: currency,
                  status: "PENDING",
                  amount_needed: {
                    crypto: total + " " + currency,
                    local:
                      periods[periods.findIndex((x) => x.value === period)]
                        .price + " USD",
                  },
                  period: period,
                  from: accounts[0],
                  method: "metamask",
                });
            })
            .on("confirmation", function (confNumber, receipt) {
              if (
                ref.current.status !== "completed" &&
                receipt.status &&
                receipt.transactionHash === ref.current.hash
              ) {
                setPayment((prev) => ({
                  ...prev,
                  confirmations: confNumber,
                  hash: receipt.transactionHash,
                  status: confNumber >= 3 ? "completed" : "pending",
                }));
              }
            })
            .on("error", function (error) {
              if (error && error.message && error.code) {
                toast({
                  title: error.message,
                  status: "info",
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                setPayment((prev) => ({
                  ...prev,
                  status: "failed",
                }));
                toast({
                  title: "Transaction Failed",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
        } else {
          let contract = new state.web3.eth.Contract(minABI, contractAddress);

          contract.methods
            .transfer(paymentAddress, value)
            .send({
              from: accounts[0],
              gasPrice: gasPrice,
              gasLimit: "0x" + (300000).toString(16),
            })
            .once("transactionHash", function (hash) {
              setPayment((prev) => ({
                ...prev,
                status: "pending",
                hash: hash,
                confirmations: 0,
                currency: currency,
                total: total,
              }));
              firebase
                .firestore()
                .collection("transactions")
                .add({
                  uid: user.uid,
                  status: "PENDING",
                  hash: hash,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  chain: chain,
                  currency: currency,
                  amount_needed: {
                    crypto: total + " " + currency,
                    local:
                      periods[periods.findIndex((x) => x.value === period)]
                        .price + " USD",
                  },
                  period: period,
                  from: accounts[0],
                  method: "metamask",
                });
            })
            .on("confirmation", function (confNumber, receipt) {
              if (
                ref.current.status !== "completed" &&
                receipt.status &&
                receipt.transactionHash === ref.current.hash
              ) {
                setPayment((prev) => ({
                  ...prev,
                  confirmations: confNumber,
                  hash: receipt.transactionHash,
                  status: confNumber >= 3 ? "completed" : "pending",
                }));
              }
            })
            .on("error", function (error) {
              if (error && error.message && error.code) {
                toast({
                  title: error.message,
                  status: "info",
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                setPayment((prev) => ({
                  ...prev,
                  status: "failed",
                }));
                toast({
                  title: "Transaction Failed",
                  description:
                    "Please check the transaction on the blockchain explorer",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <>
      <Button
        variant="outline"
        isFullWidth
        mt={10}
        size={"lg"}
        onClick={openModal}
      >
        <Icon as={FaCreditCard} mr={2} fontSize={20} />
        Pay &amp; Subscribe
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        {!payment ? (
          <ModalContent>
            <ModalHeader>
              <Text variant={"Gagalin"}>Select Network</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex justifyContent={"space-between"} mb={10}>
                {Object.keys(chains).map((key, index) => {
                  return (
                    <VStack
                      w={"full"}
                      px={4}
                      id={key}
                      key={index}
                      pos={"relative"}
                    >
                      <Image
                        src={chains[key].logo}
                        w={32}
                        shadow={"lg"}
                        filter={key === "Ropsten" ? "hue-rotate(90deg);" : ""}
                        borderRadius={"50%"}
                        _hover={{
                          cursor: "pointer",
                          transform: "scale(1.02)",
                        }}
                        transition={"0.5s ease"}
                        onClick={async () => {
                          setChain(key);
                        }}
                      />
                      <Box
                        bg={"white"}
                        shadow={"lg"}
                        borderRadius={"50%"}
                        pos={"absolute"}
                        right={"10px"}
                        display={chain !== key ? "none" : "block"}
                        padding={0.5}
                      >
                        <Icon
                          as={FaCheckCircle}
                          fontSize={"2rem"}
                          color={"green.400"}
                        />
                      </Box>
                    </VStack>
                  );
                })}
              </Flex>
              <Text textAlign={"center"} fontSize={"sm"} variant={"Gagalin"}>
                Tip: Use Binance Network for lower transaction fees.
                <LinkUI
                  _focus={{ boxShadow: "unset" }}
                  isExternal
                  display={"block"}
                  color={"orange.900"}
                  href="https://docs.binance.org/smart-chain/wallet/metamask.html#connect-your-metamask-with-binance-smart-chain"
                >
                  Learn More
                </LinkUI>
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                isFullWidth
                disabled={chain === ""}
                onClick={async () => {
                  await sendPayment(currency, total, period);
                }}
              >
                PAY
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <TransactionModal
            status={payment.status}
            currency={payment.currency}
            total={payment.total}
            confirmations={payment.confirmations}
            hash={payment.hash}
            onClose={onClose}
            blockchain={chains[chain].blockchain}
            viewAccounts={true}
          />
        )}
      </Modal>
    </>
  );
}

async function getPrice(currency: any, period: any, method: any) {
  const currentPeriod = periods.find((x) => x.value === period);
  const price = !currentPeriod ? 360.0 : currentPeriod.price;
  if (
    currency === "BUSD" ||
    currency === "USDT" ||
    currency === "USDC" ||
    currency === "DAI"
  )
    return price.toFixed(2);

  if (method === "metamask" && currency !== "ETH") {
    return fetch(
      `https://api.binance.com/api/v3/avgPrice?symbol=${currency}BUSD`
    )
      .then((res) => res.json())
      .then((result) => {
        var final = price / result.price;
        if (currency === "BTC") final = parseFloat(final.toFixed(8));
        else final = parseFloat(final.toFixed(6));
        return final;
      });
  } else {
    return fetch(`https://api.coinbase.com/v2/prices/${currency}-USD/spot`)
      .then((res) => res.json())
      .then((result) => {
        var final = price / result.data.amount;
        if (currency === "BTC") final = parseFloat(final.toFixed(8));
        else final = parseFloat(final.toFixed(6));
        return final;
      });
  }
}
