import React, { useState } from "react";
import {
  Box,
  Flex,
  Stack,
  Button,
  useColorModeValue,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from "@chakra-ui/react";
import {
  PageHeader,
  LoadingCard,
  ErrorCard,
  capitalizeFirstLetter,
} from "../../components/helpers/Helpers";

import { Formik, Form, Field } from "formik";

import { FaCalendar, FaCoins, FaTrash, FaPen } from "react-icons/fa";

import firebase from "../../services/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  GetLastMonthSelected,
  GetStats,
} from "../../components/statistics/statistics";

export default function AdminStatistics() {
  const userRef = firebase.firestore().collection("statistics").doc("Months");
  const [value, loading, error] = useDocumentData(userRef);
  const [selectedValue, setSelectedValue] = useState(GetLastMonthSelected);
  const [selectedCoin, setSelectedCoin] = useState<any>(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      minH="96vh"
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
    >
      <Flex w={"full"} px={{ base: 4, md: 6 }} py={10} flexDir={"column"}>
        <PageHeader title={"Statistics"} />
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"md"}
          p={6}
          mt={10}
        >
          <Stack
            align={"center"}
            justifyContent={"center"}
            direction={{ base: "column", md: "row" }}
          >
            {loading && <LoadingCard />}
            {error && <ErrorCard />}
            {value && (
              <>
                <Stack direction={"row"}>
                  <NewMonth />
                  {selectedValue && <NewCoin month={selectedValue} />}
                </Stack>
                <Select
                  placeholder="Select Month"
                  defaultValue={
                    value.dates.filter(
                      (option) =>
                        option.replace(/\s/g, "").toLowerCase() ===
                        GetLastMonthSelected()
                    )[0]
                  }
                  onChange={(e) =>
                    setSelectedValue(
                      e.currentTarget.value.replace(/\s/g, "").toLowerCase()
                    )
                  }
                  _hover={{ cursor: "pointer" }}
                >
                  {value.dates.map((child: string) => (
                    <option key={child}>{child}</option>
                  ))}
                </Select>
                {selectedValue && <DeleteMonth selectedMonth={selectedValue} />}
              </>
            )}
          </Stack>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={4}
        >
          {selectedValue && (
            <GetStats
              month={selectedValue}
              extraColumnHeaders={[
                {
                  Header: "Action",
                  Cell: (row: any) => {
                    const data = row.row.original;
                    return (
                      <Flex>
                        <IconButton
                          aria-label="Edit Coin"
                          icon={<FaPen />}
                          variant={"outline"}
                          mr={3}
                          onClick={() => {
                            setSelectedCoin({ ...data, index: row.row.index });
                            onOpen();
                          }}
                        />

                        <IconButton
                          aria-label="Delete Coin"
                          variant={"delete"}
                          icon={<FaTrash />}
                          onClick={() =>
                            DeleteCoin(
                              JSON.stringify(row.row.original),
                              selectedValue,
                              toast
                            )
                          }
                        />
                      </Flex>
                    );
                  },
                },
              ]}
            />
          )}
        </Box>

        {selectedCoin && (
          <EditCoin
            month={selectedValue}
            coin={selectedCoin}
            onClose={onClose}
            isOpen={isOpen}
          />
        )}
      </Flex>
    </Flex>
  );
}

async function DeleteCoin(test: any, month: string, toast: any) {
  const monthRef = firebase.firestore().collection("statistics").doc(month);

  await monthRef
    .set(
      {
        results: firebase.firestore.FieldValue.arrayRemove(JSON.parse(test)),
      },
      { merge: true }
    )
    .then(() => {
      toast({
        title: "Coin Deleted Successfully 👍",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    })
    .catch((err) => {
      toast({
        title: "Error Occurred 😭",
        description: err.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
}

function EditCoin({ month, coin, onClose, isOpen }: any) {
  const toast = useToast();

  var leverage: any[] = [<option key="None">None</option>];

  for (var i = 1; i <= 25; i++) {
    leverage.push(<option key={i}>{i}x</option>);
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"md"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader textAlign={"center"}>Edit Coin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={coin}
            onSubmit={async (values: any, actions: any) => {
              await firebase
                .firestore()
                .collection("statistics")
                .doc(month)
                .get()
                .then((doc: any) => {
                  if (doc && doc.exists) {
                    let results = doc.data().results;
                    results[coin.index] = {
                      coin: values.coin,
                      position: values.position,
                      leverage: values.leverage,
                      entry: values.entry,
                      closed: values.closed,
                    };
                    doc.ref
                      .set({
                        results: results,
                      })
                      .then(() => {
                        toast({
                          title: "Updated Successfully 👍",
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });
                      })
                      .catch((err) => {
                        toast({
                          title: "Error Occurred ⚠️",
                          description: err.message,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      });
                  }
                })
                .catch((err) => {
                  toast({
                    title: "Error Occurred ⚠️",
                    description: err.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                });
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="coin">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="coin">Coin Name</FormLabel>
                        <Input {...field} id="coin" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="position">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="position">Position</FormLabel>
                        <Select {...field} id="position">
                          <option key="LONG">LONG</option>
                          <option key="SHORT">SHORT</option>
                          <option key="LONG SCALP">LONG SCALP</option>
                          <option key="SHORT SCALP">SHORT SCALP</option>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="leverage">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="leverage">Leverage</FormLabel>
                        <Select {...field} id="leverage">
                          {leverage}
                        </Select>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="entry">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="entry">Entry Price</FormLabel>
                        <Input {...field} id="entry" />
                      </FormControl>
                    )}
                  </Field>

                  <Field name="closed">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel htmlFor="closed">Closed Price</FormLabel>
                        <Input {...field} id="closed" />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  variant={"outline"}
                  mt={10}
                  mb={6}
                  isFullWidth
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function NewCoin(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const formValues = {
    coin: "",
    position: "LONG",
    leverage: "None",
    entry: 0,
    closed: 0,
  };

  var leverage: any[] = [<option key="None">None</option>];

  for (var i = 1; i <= 25; i++) {
    leverage.push(<option key={i}>{i}x</option>);
  }

  const monthRef = firebase
    .firestore()
    .collection("statistics")
    .doc(props.month);

  return (
    <>
      <Button leftIcon={<FaCoins />} onClick={onOpen} variant={"outline"}>
        Coin
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
          <ModalHeader textAlign={"center"}>New Entry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              onSubmit={async (values: any, actions: any) => {
                await monthRef
                  .set(
                    {
                      results: firebase.firestore.FieldValue.arrayUnion({
                        coin: values.coin,
                        position: values.position,
                        leverage: values.leverage,
                        entry: values.entry,
                        closed: values.closed,
                        added: +new Date(),
                      }),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    toast({
                      title: "Coin Added Successfully 👍",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });
                    onClose();
                  })
                  .catch((err) => {
                    toast({
                      title: "Error Occurred 😭",
                      description: err.message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  });

                actions.setSubmitting(false);
              }}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <Stack spacing={4}>
                    <Field name="coin">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="coin">Coin Name</FormLabel>
                          <Input {...field} id="coin" />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="position">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="position">Position</FormLabel>
                          <Select {...field} id="position">
                            <option key="LONG">LONG</option>
                            <option key="SHORT">SHORT</option>
                            <option key="LONG SCALP">LONG SCALP</option>
                            <option key="SHORT SCALP">SHORT SCALP</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="leverage">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="leverage">Leverage</FormLabel>
                          <Select {...field} id="leverage">
                            {leverage}
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="entry">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="entry">Entry Price</FormLabel>
                          <Input {...field} id="entry" />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="closed">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="closed">Closed Price</FormLabel>
                          <Input {...field} id="closed" />
                        </FormControl>
                      )}
                    </Field>

                    <Stack spacing={10}>
                      <Button
                        variant={"outline"}
                        mb={4}
                        isLoading={isSubmitting}
                        type="submit"
                      >
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function NewMonth() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const monthRef = firebase.firestore().collection("statistics").doc("Months");

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  const currentYear = d.getFullYear();
  const currentMonth = monthNames[d.getMonth()];

  const formValues = { year: currentYear, month: currentMonth };

  return (
    <>
      <Button leftIcon={<FaCalendar />} onClick={onOpen} variant={"outline"}>
        Month
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
          <ModalHeader textAlign={"center"}>New Entry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              onSubmit={async (values: any, actions: any) => {
                const monthYear = values.month + " " + values.year;

                await monthRef
                  .update({
                    dates: firebase.firestore.FieldValue.arrayUnion(monthYear),
                  })
                  .then(() => {
                    toast({
                      title: "Month Added Successfully 👍",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });
                    onClose();
                  })
                  .catch((err) => {
                    toast({
                      title: "Error Occurred 😭",
                      description: err.message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  });

                actions.setSubmitting(false);
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <Field name="month">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="month">Month</FormLabel>
                          <Select {...field} id="month">
                            {monthNames.map((res) => (
                              <option key={res}>{res}</option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="year">
                      {({ field, form }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="year">Year</FormLabel>
                          <NumberInput
                            defaultValue={currentYear}
                            onChange={(e) => {
                              setFieldValue("year", e);
                            }}
                            min={currentYear - 2}
                            max={currentYear + 1}
                            id="year"
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={10}>
                      <Button
                        variant={"outline"}
                        my={4}
                        isLoading={isSubmitting}
                        type="submit"
                      >
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function DeleteMonth({ selectedMonth }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const monthRef = firebase.firestore().collection("statistics");
  const monthSplit = selectedMonth.match(/([^2]*)2(.*)/);
  const dateValue = capitalizeFirstLetter(monthSplit[1]) + " 2" + monthSplit[2];

  const deleteConfirm = async () => {
    await monthRef
      .doc("Months")
      .set(
        {
          dates: firebase.firestore.FieldValue.arrayRemove(dateValue),
        },
        { merge: true }
      )
      .then(async () => {
        await monthRef
          .doc(selectedMonth)
          .delete()
          .then(() => {
            toast({
              title: "Month Deleted Successfully 👍",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            onClose();
          });
      })
      .catch((err) => {
        toast({
          title: "Error Occurred 😭",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button leftIcon={<FaTrash />} onClick={onOpen} variant={"delete"}>
        Delete
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Delete Month</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={"center"}>
              This actions is irreversible! This will delete all the content for
              the month.
              <br />
              <br />
              Are you sure you want to delete the month?
            </Text>
            <Stack direction={"row"} mt={8} mb={4}>
              <Button
                variant={"delete"}
                onClick={async () => {
                  deleteConfirm();
                }}
                isFullWidth
              >
                Delete
              </Button>
              <Button variant={"outline"} onClick={onClose} isFullWidth>
                Return
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
