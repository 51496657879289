import React from "react";
import { Flex, useColorModeValue, Grid } from "@chakra-ui/react";
import { FaTags } from "react-icons/fa";
import {
  MdDashboard,
  MdQuestionAnswer,
  MdPerson,
  MdBook,
  MdShowChart,
  MdStar,
  MdHelp,
} from "react-icons/md";
import { PageHeader, DashCard } from "../components/helpers/Helpers";

import "../components/dashboard/dashboard.css";

export default function Dashboard(props: any) {
  return (
    <Flex
      minH="96vh"
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "blue.900")}
      flexDir={"column"}
    >
      <PageHeader title={"Dashboard"} mt={2} />
      <Grid
        padding={{ base: 4, md: 6 }}
        templateColumns={{
          base: "repeat(2,  1fr)",
          lg: "repeat(3, 1fr)",
        }}
        templateRows={{
          base: "repeat(2,  minmax(170px, 1fr))",
          lg: "repeat(2, minmax(220px, 1fr))",
        }}
        alignItems={"center"}
        gap={{ base: 15, md: 20 }}
        w={"full"}
        justifyContent={"center"}
      >
        <DashCard
          title={"Portfolio"}
          icon={MdDashboard}
          comingSoon
          page={"portfolio"}
        />
        <DashCard
          title={"Statistics"}
          icon={MdShowChart}
          page={"statistics"}
          query={{ noTransition: true }}
        />
        <DashCard
          title={"Subscriptions"}
          icon={FaTags}
          padding={true}
          page={"subscriptions"}
        />
        <DashCard title={"Profile"} icon={MdPerson} page={"profile"} />
        <DashCard title={"Resources"} icon={MdBook} page={"resources"} />
        <DashCard title={"Referrals"} icon={MdStar} page={"referrals"} />
        <DashCard title={"FAQ"} icon={MdQuestionAnswer} page={"FAQ"} />
        <DashCard title={"Support"} icon={MdHelp} page={"support"} />

        {/* <DashCard title={"Tutorials"} image={"dddd"} /> */}
        {/* <DashCard title={"Glossary & Acronyms"} image={"dddd"} /> */}
        {/* <DashCard title={"Settings"} icon={MdSettings} page={"settings"} /> */}
      </Grid>
    </Flex>
  );
}
