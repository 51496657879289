import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  HStack,
  Img,
  Alert,
  AlertIcon,
  AlertDescription,
  chakra,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook, FaTwitter } from "react-icons/fa";

import { useAuth } from "../components/auth/useAuth";
import firebase from "../services/firebase";
import "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { Formik, Form, Field } from "formik";
import { Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import { ErrorCard, LoadingCard } from "../components/helpers/Helpers";

import logo from "../assets/images/logo.png";

const containerVariants2 = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { ease: "easeInOut", duration: 1.2 },
  },
  exit: {
    opacity: 0,
    transition: { ease: "easeInOut" },
  },
};

export default function Referral(props: any) {
  const { user, TwitterAuth, FacebookAuth, GoogleAuth } = useAuth();
  const [authError, setAuthError] = useState(null);
  const referralID = props.match.params.referralID;
  const referralRef = firebase
    .firestore()
    .collection("referrals")
    .limit(1)
    .where("usernameLower", "==", referralID.toLowerCase());

  const [value, loading, error] = useCollection(referralRef);

  const colors = [
    useColorModeValue("gray.50", "blue.900"),
    useColorModeValue("white", "gray.800"),
  ];
  if (user) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants2}
      exit="exit"
    >
      <Flex minH="96vh" align={"center"} justify={"center"} bg={colors[0]}>
        <Stack spacing={8} mx={"auto"} maxW={"md"} py={12} px={6}>
          {value && value.size > 0 && (
            <>
              <Stack align={"center"}>
                <Img src={logo} w={"10rem"} />
                <Heading fontSize={"4xl"} textAlign="center">
                  Sign <chakra.span color="orange.900">Up</chakra.span>
                </Heading>
              </Stack>
              {authError && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription textAlign="center">
                    {authError}
                  </AlertDescription>
                </Alert>
              )}

              <Box rounded={"lg"} bg={colors[1]} boxShadow={"lg"} p={8}>
                <HStack mb="5">
                  <Button
                    colorScheme="red"
                    leftIcon={<FaGoogle />}
                    onClick={async () => {
                      try {
                        await GoogleAuth(true, false, value.docs[0].id);
                      } catch (e) {
                        setAuthError(e.message);
                      }
                    }}
                  >
                    Google
                  </Button>
                  <Button
                    colorScheme="facebook"
                    leftIcon={<FaFacebook />}
                    onClick={async () => {
                      try {
                        await FacebookAuth(true, false, value.docs[0].id);
                      } catch (e) {
                        setAuthError(e.message);
                      }
                    }}
                  >
                    Facebook
                  </Button>
                  <Button
                    colorScheme="twitter"
                    leftIcon={<FaTwitter />}
                    onClick={async () => {
                      try {
                        await TwitterAuth(true, false, value.docs[0].id);
                      } catch (e) {
                        setAuthError(e.message);
                      }
                    }}
                  >
                    Twitter
                  </Button>
                </HStack>

                <Text textAlign="center" color={"gray.500"}>
                  OR
                </Text>
                <LoginUI
                  referralUsername={referralID}
                  referralID={value.docs[0].id}
                />
              </Box>
            </>
          )}{" "}
          {!value && !loading && !error && (
            <Box
              rounded={"lg"}
              bg={colors[1]}
              boxShadow={"lg"}
              p={8}
              w={"full"}
            >
              <Heading color={"orange.900"} textAlign={"center"}>
                The invite link is invalid
              </Heading>
            </Box>
          )}
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
        </Stack>
      </Flex>
    </motion.div>
  );
}

function LoginUI({ referralUsername, referralID }: any) {
  function validateEmail(value: any) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePassword(value: any) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  }

  function validateConfirmPassword(value: any) {
    let error;
    if (!value) {
      error = "Confirm password is required";
    }
    return error;
  }

  const { SignUp } = useAuth();

  return (
    <Formik
      initialValues={{ email: "", password: "", confirmPassword: "", auth: "" }}
      onSubmit={async (values: any, actions: any) => {
        if (values.confirmPassword !== values.password) {
          actions.setErrors({ auth: "Passwords do not match!" });
        } else {
          try {
            await SignUp(values.email, values.password, false, referralID);
          } catch (error) {
            actions.setErrors({ auth: error.message });
          }
        }
        actions.setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Stack spacing={4}>
            {errors.auth && (
              <Text align="center" fontSize="sm" mt="2" color={"red.500"}>
                {errors.auth}
              </Text>
            )}
            <Field name="email" validate={validateEmail}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="Email"
                    autoComplete="username"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password" validate={validatePassword}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                >
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    {...field}
                    id="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmPassword" validate={validateConfirmPassword}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmPassword && form.touched.confirmPassword
                  }
                >
                  <FormLabel htmlFor="confirmPassword">
                    Confirm Password
                  </FormLabel>
                  <Input
                    {...field}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <FormErrorMessage>
                    {form.errors.confirmPassword}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field>
              {({ field, form }: any) => (
                <FormControl>
                  <FormLabel>Referral Code</FormLabel>
                  <Input isReadOnly value={referralUsername} />
                </FormControl>
              )}
            </Field>
            <Stack spacing={10}>
              <Button
                variant={"outline"}
                mt={6}
                isLoading={isSubmitting}
                type="submit"
              >
                Sign Up
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
