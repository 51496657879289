import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  ListIcon,
  ModalContent,
  ModalFooter,
  ModalBody,
  Heading,
  Spinner,
  Link as LinkUI,
} from "@chakra-ui/react";

import {
  FaCalendar,
  FaCheckCircle,
  FaCoins,
  FaCube,
  FaDollarSign,
  FaExclamationTriangle,
  FaExternalLinkAlt,
  FaLink,
  FaReceipt,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { ClickableContact } from "../helpers/Helpers";

const ModalPayment = (props: any) => {
  const history = useHistory();
  // 20 minutes from when the transaction was created
  const dateCreatedTimePassed = new Date(
    new Date(props.createdAtTimeStamp + 20 * 60000)
  );
  return (
    <ModalContent>
      <ModalBody minH={"lg"} display={"flex"}>
        {props.status === "pending" &&
        (!props.createdAtTimeStamp ||
          (props.createdAtTimeStamp &&
            dateCreatedTimePassed.getTime() > new Date().getTime())) ? (
          <Flex flexDir={"column"} textAlign={"center"} mt={5} w={"full"}>
            <Heading size={"lg"} color={"orange.900"}>
              Payment Detected
            </Heading>
            <Text fontSize="xs" mb={5}>
              Waiting for 3 confirmations
            </Text>
            <Flex
              w={"full"}
              textAlign={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.900"
                size="xl"
                my={6}
              />
            </Flex>
            <List spacing={4} textAlign={"left"} mt={10} fontSize={"lg"}>
              <ListItem>
                <ListIcon as={FaReceipt} color="orange.900" fontSize={"xl"} />
                {props.currency}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCoins} color="orange.900" fontSize={"xl"} />
                {props.total}
              </ListItem>
              {props.totalLocal && (
                <ListItem>
                  <ListIcon
                    as={FaDollarSign}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.totalLocal}
                </ListItem>
              )}
              {(props.confirmations || props.confirmations === 0) && (
                <ListItem>
                  <ListIcon
                    as={FaCheckCircle}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.confirmations +
                    (props.confirmations === 1
                      ? " Confirmation"
                      : " Confirmations")}
                </ListItem>
              )}
              {props.chain && (
                <ListItem>
                  <ListIcon as={FaCube} color="orange.900" fontSize={"xl"} />
                  {props.chain}
                </ListItem>
              )}
              {props.createdAt && (
                <ListItem>
                  <ListIcon
                    as={FaCalendar}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.createdAt}
                </ListItem>
              )}
              <ListItem>
                <ListIcon as={FaLink} color="orange.900" fontSize={"xl"} />
                <LinkUI href={props.blockchain + "tx/" + props.hash} isExternal>
                  View on Blockchain{" "}
                  <Icon as={FaExternalLinkAlt} fontSize={"xs"} />
                </LinkUI>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaExclamationTriangle}
                  color="orange.900"
                  fontSize={"xl"}
                />
                <ClickableContact />
              </ListItem>
            </List>
            <Text fontSize="xs" mt={5}>
              This dialog can be closed. Your payment will be{" "}
              <b>automatically </b>
              credited once the transaction receives at least 3 confirmations.
            </Text>
          </Flex>
        ) : props.status === "completed" ? (
          <Flex flexDir={"column"} textAlign={"center"} mt={5} w={"full"}>
            <Heading size={"lg"} color={"orange.900"}>
              Payment Successful
            </Heading>
            <Icon
              as={FaCheckCircle}
              color={"green.500"}
              fontSize={"10rem"}
              display={"inline"}
              alignSelf={"center"}
              my={6}
            />
            <List spacing={4} textAlign={"left"} fontSize={"lg"} mt={10}>
              <ListItem>
                <ListIcon as={FaReceipt} color="orange.900" fontSize={"xl"} />
                {props.currency}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCoins} color="orange.900" fontSize={"xl"} />
                {props.total}
              </ListItem>
              {props.totalLocal && (
                <ListItem>
                  <ListIcon
                    as={FaDollarSign}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.totalLocal}
                </ListItem>
              )}
              {props.confirmations && (
                <ListItem>
                  <ListIcon
                    as={FaCheckCircle}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.confirmations +
                    (props.confirmations === 1
                      ? " Confirmation"
                      : " Confirmations")}
                </ListItem>
              )}
              {props.chain && (
                <ListItem>
                  <ListIcon as={FaCube} color="orange.900" fontSize={"xl"} />
                  {props.chain}
                </ListItem>
              )}
              {props.createdAt && (
                <ListItem>
                  <ListIcon
                    as={FaCalendar}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.createdAt}
                </ListItem>
              )}
              <ListItem>
                <ListIcon as={FaLink} color="orange.900" fontSize={"xl"} />
                <LinkUI href={props.blockchain + "tx/" + props.hash} isExternal>
                  View on Blockchain{" "}
                  <Icon as={FaExternalLinkAlt} fontSize={"xs"} />
                </LinkUI>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaExclamationTriangle}
                  color="orange.900"
                  fontSize={"xl"}
                />
                <ClickableContact />
              </ListItem>
            </List>
            {props.viewAccounts && (
              <Button
                mt={6}
                variant={"outline"}
                onClick={() => {
                  history.push({
                    pathname: "/profile",
                    state: { tab: "accounts" },
                  });
                }}
              >
                Join CryptoKnights Chats
              </Button>
            )}
          </Flex>
        ) : props.status === "failed" ? (
          <Flex flexDir={"column"} textAlign={"center"} mt={5} w={"full"}>
            <Heading size={"lg"} color={"orange.900"}>
              Payment Failed
            </Heading>
            <Icon
              as={FaExclamationTriangle}
              color={"red.500"}
              fontSize={"10rem"}
              display={"inline"}
              alignSelf={"center"}
              my={6}
            />
            <List spacing={4} textAlign={"left"} fontSize={"lg"} mt={10}>
              <ListItem>
                <ListIcon as={FaReceipt} color="orange.900" fontSize={"xl"} />
                {props.currency}
              </ListItem>
              <ListItem>
                <ListIcon as={FaCoins} color="orange.900" fontSize={"xl"} />
                {props.total}
              </ListItem>
              {props.totalLocal && (
                <ListItem>
                  <ListIcon
                    as={FaDollarSign}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.totalLocal}
                </ListItem>
              )}
              {props.chain && (
                <ListItem>
                  <ListIcon as={FaCube} color="orange.900" fontSize={"xl"} />
                  {props.chain}
                </ListItem>
              )}
              {props.createdAt && (
                <ListItem>
                  <ListIcon
                    as={FaCalendar}
                    color="orange.900"
                    fontSize={"xl"}
                  />
                  {props.createdAt}
                </ListItem>
              )}
              <ListItem>
                <ListIcon as={FaLink} color="orange.900" fontSize={"xl"} />
                <LinkUI href={props.blockchain + "tx/" + props.hash} isExternal>
                  View on Blockchain{" "}
                  <Icon as={FaExternalLinkAlt} fontSize={"xs"} />
                </LinkUI>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaExclamationTriangle}
                  color="orange.900"
                  fontSize={"xl"}
                />
                <ClickableContact />
              </ListItem>
            </List>
          </Flex>
        ) : (
          <Flex
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            w={"full"}
          >
            <Box textAlign={"center"} w={"full"}>
              <Icon
                as={FaExclamationTriangle}
                fontSize={"8rem"}
                color={"red.500"}
              />
              <Text my={5} fontSize="xl">
                Error Occurred!
              </Text>
              <ClickableContact />
            </Box>
          </Flex>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.onClose} variant="outline">
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default ModalPayment;
