import React from "react";
import {
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  useDisclosure,
  Spinner,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FaEye, FaExclamationTriangle } from "react-icons/fa";

import { useAuth } from "../auth/useAuth";
import firebase from "../../services/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import DataTable from "../admin/statistics/table";
import { Column } from "react-table";

import TransactionModal from "../transaction/modal";
import { chains } from "../../services/subscriptions";
import { ClickableContact } from "../helpers/Helpers";

export default function PaymentsView(props: any) {
  const { user } = useAuth();

  const tranRef = firebase
    .firestore()
    .collection("transactions")
    .where("uid", "==", props.uid ? props.uid : user.uid)
    .orderBy("createdAt", "desc");

  const [values, loading, error] = useCollectionData(tranRef);
  return values && values.length > 0 ? (
    <DataTable columns={columnHeaders} data={values} />
  ) : loading ? (
    <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="orange.900"
        size="xl"
        my={6}
      />
      <Text textAlign="center" fontSize={"lg"}>
        Please Wait
      </Text>
    </Flex>
  ) : error ? (
    <Flex flexDir={"column"} align={"center"}>
      <Icon
        as={FaExclamationTriangle}
        fontSize={"8rem"}
        mb={4}
        color={"red.500"}
      />
      <Text fontSize={"xl"}>
        An error occurred, please try again later. If the issue persists please{" "}
        <ClickableContact />
      </Text>
    </Flex>
  ) : (
    <Flex flexDir={"column"} align={"center"}>
      <Icon
        as={FaExclamationTriangle}
        fontSize={"8rem"}
        mb={4}
        color={"orange.900"}
      />
      <Text fontSize={"xl"}>
        There are currently no payments associated to this account
      </Text>
    </Flex>
  );
}

const columnHeaders: Column<any>[] = [
  {
    Header: "Amount",
    accessor: "amount",
    Cell: (row) => {
      const data = row.row.original;
      const local =
        data.method === "coinbase"
          ? data.amount_paid.local
          : data.status === "COMPLETED"
          ? data.amount_paid.local
          : data.status === "REDEEMED"
          ? data.amount_redeemed
          : data.amount_needed.local;
      return <Text>{local}</Text>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => {
      const data = row.row.original;
      if (data.status === "COMPLETED")
        return <Text color={"green.500"}>{data.status}</Text>;
      else if (data.status === "PENDING") {
        const dateCreatedTimePassed = new Date(
          new Date(
            new Date(data.createdAt.seconds * 1000).getTime() + 20 * 60000
          )
        );
        if (dateCreatedTimePassed.getTime() > new Date().getTime())
          return <Text color={"orange.900"}>{data.status}</Text>;
        else return <Text color={"red.500"}>Error</Text>;
      } else if (data.status === "REDEEMED")
        return <Text color={"orange.900"}>{data.status}</Text>;
      else return <Text color={"red.500"}>{data.status}</Text>;
    },
  },
  {
    Header: "Method",
    accessor: "method",
  },
  {
    Header: "Period",
    accessor: "period",
    Cell: (row) => {
      const data = row.row.original;
      return <Text>{data.period} Months</Text>;
    },
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: (row) => {
      const data = row.row.original;
      return (
        <Text>{new Date(data.createdAt.seconds * 1000).toDateString()}</Text>
      );
    },
  },
  {
    Header: "Action",
    Cell: (row: any) => {
      const data = row.row.original;
      if (data.status === "REDEEMED")
        return (
          <IconButton
            disabled
            icon={<FaEye />}
            aria-label="View"
            variant="outline"
          />
        );
      else return <OpenPayment payment={data} />;
    },
  },
];

function OpenPayment({ payment }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const total =
    payment.method === "coinbase"
      ? payment.amount_paid.crypto
      : payment.status === "COMPLETED"
      ? payment.amount_paid.crypto
      : payment.amount_needed.crypto;

  const totalLocal =
    payment.method === "coinbase"
      ? payment.amount_paid.local
      : payment.status === "COMPLETED"
      ? payment.amount_paid.local
      : payment.amount_needed.local;
  return (
    <>
      <IconButton
        icon={<FaEye />}
        aria-label="View"
        onClick={onOpen}
        variant="outline"
      />
      {isOpen && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <TransactionModal
            status={payment.status.toLowerCase()}
            currency={payment.currency}
            total={total}
            createdAt={new Date(
              payment.createdAt.seconds * 1000
            ).toDateString()}
            createdAtTimeStamp={new Date(
              payment.createdAt.seconds * 1000
            ).getTime()}
            chain={payment.chain}
            hash={payment.hash}
            onClose={onClose}
            totalLocal={totalLocal}
            blockchain={chains[payment.chain].blockchain}
          />
        </Modal>
      )}
    </>
  );
}
