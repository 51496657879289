import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function AdminRoute({ component: Component, ...rest }: any) {
  const { user, isAdmin } = useAuth();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin - {rest.title}</title>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          return user && isAdmin ? (
            <Component {...props} />
          ) : (
            <Redirect to="/signin" />
          );
        }}
      ></Route>
    </>
  );
}
