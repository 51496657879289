import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import {
  Box,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  Heading,
  Image,
  Button,
} from "@chakra-ui/react";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100vw" : "-100vw",
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "100vw" : "-100vw",
    };
  },
};

export const TestimonialsSlider = (props: any) => {
  const randomInt = Math.floor(Math.random() * props.values.length);
  const [[page, direction], setPage] = useState([randomInt, randomInt]);
  const [looper, setLopper] = useState(0);

  const testimonialIndex = wrap(0, props.values.length, page);

  const colors = [useColorModeValue("white", "gray.800")];

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
    setLopper(1);
  };

  var timeout = setTimeout(() => {
    if (looper === 1) clearTimeout(timeout);
    else paginate(1);
  }, 5000);

  return (
    <Flex alignItems={"center"} w={"full"} justifyContent={"space-between"}>
      <Button onClick={() => paginate(1)} variant={"outline"} ml={-2} mr={2}>
        <Icon as={FaChevronLeft} />
      </Button>
      <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
        <motion.div
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          <Flex h={"250px"} w={"full"} alignItems={"center"}>
            <Image
              src="logo512.png"
              w={"250px"}
              mr={3}
              display={{ base: "none", md: "block" }}
            />

            <Box bg={colors[0]} p={5} rounded={"xl"} w={"full"} shadow={"xl"}>
              <Heading size={"lg"} mb={2} color={"orange.900"}>
                {props.values[testimonialIndex].username}
              </Heading>
              <Text>{props.values[testimonialIndex].message}</Text>
            </Box>
          </Flex>
        </motion.div>
      </AnimatePresence>
      <Button onClick={() => paginate(-1)} variant={"outline"} ml={2} mr={-2}>
        <Icon as={FaChevronRight} />
      </Button>
    </Flex>
  );
};
